
export const ID = 'ID';
export const SAVE_PHOTO = 'SAVE_PHOTO'; 
export const SAVE_URL_ID = 'SAVE_URL_ID';
export const SAVE_URL_ROOM = 'SAVE_URL_ROOM';
export const SAVE_DATE = 'SAVE_DATE'; 
export const SAVE_STATUS = 'SAVE_STATUS'; 
export const SAVE_STEP = 'SAVE_STEP';
export const SAVE_INSPECTION = 'SAVE_INSPECTION';
export const DELETE_PHOTO = 'DELETE_PHOTO'; 
export const SET_MUTE = 'SET_MUTE'; 
export const SET_GRID_GUIDE = 'SET_GRID_GUIDE'; 
export const SET_GUIDE = 'SET_GUIDE';
export const SET_PHOTO = 'SET_PHOTO'; 
export const DETECTOR_D3 = 'DETECTOR_D3';

export function saveStatus(id, status) { 
  return { type: SAVE_STATUS, payload: {id, status} }
}

export function savePhoto(id, photo, image_id) { 
  return { type: SAVE_PHOTO, payload: {id, photo, image_id} }
}

export function deletePhoto(id,   image_id) { 
  return { type: DELETE_PHOTO, payload: {id, image_id} }
}

export function saveId(id) { 
  return { type: ID, payload: id }
}

export function saveUrlId(id) {
  return { type: SAVE_URL_ID, payload: id }
}

export function saveUrlRoom(room) {
  return { type: SAVE_URL_ROOM, payload: room }
}

export function saveInspection(inspection) {
  return { type: SAVE_INSPECTION, payload: inspection }
}

export function saveDate(date) {
  return { type: SAVE_DATE, payload: date }
}

export function saveStep(step) {
  return {type: SAVE_STEP, payload: step}
}

export function setMuteAudio(step) {
  return {type: SET_MUTE, payload: step}
}

export function setPhoto(id, name, position, photo) {
  return { type: SET_PHOTO, payload: {id, name, position, photo} }
}

export function setGridGuide(guide) { 
  return { type: SET_GRID_GUIDE, payload: guide }
}

export function saveGuide(guide) {
  return { type: SET_GUIDE, payload: guide }
}