import Axios from 'axios'; 
import { config } from '../config';


export const getYears = async () => {
  const yearsArr = await Axios.get(`${config.CAR_INFORMATION_URL}/catalog/years`)
    .then( ({ data })  => {
      const arrayData = data.map(
        year => {
          return {'label': year, 'value': year};
        }
      );
      return arrayData;

    })
    .catch(err => {
      console.log('error at retreiving years: ', err)
      return [];
    });

  return yearsArr;
}

export const getModels = async brandId => {
  const models = await Axios.get(`${config.CAR_INFORMATION_URL}/catalog/model/${brandId}`) 
      .then( ({ data }) => {
          return data;
      })
      .catch(err => {
          console.log('error at retreiving brands: ', err);
          return [];
      });

  return models;
}

export const getVersionsByModel = async modelId => {
  const versions = await Axios.get(`${config.CAR_INFORMATION_URL}/catalog/version/${modelId }`)
    .then( ({ data }) => {
        return data;
    })
    .catch(err => {
      console.log('error at retreiving versions: ', err);
      return [];
    });

  return versions;
}

export const getBrands = async () => {
  const brands =  await Axios.get(`${config.CAR_INFORMATION_URL}/catalog/brand/`)
    .then( ({ data })  => {
        return data;
    })
    .catch(err => {
      console.log('error at retreiving brands: ', err);
      return [];
    });

  return brands;
}


