import React, { useEffect, useRef, useState } from "react";
import {
  useHistory
} from "react-router-dom";
import { connect } from 'react-redux';
import Button from '../../components/Button/ButtonFilled';
import Header from './components/header';

const VideoConfJoin = ({ inspection }) => {
    const history = useHistory();
    const localVideo = useRef(null);
    const [localRefStream, setLocalRefStream] = useState(null);
    const [isError, setIsError] = useState(false);

    let _stream = null;

    let localStreamConstraints = {
        audio: true,
        video: true
    };

    const gotStream = (stream) => {
        setLocalRefStream(stream);
        _stream = stream;
        localVideo.current.srcObject = stream;
    }

    useEffect(() => {
        navigator.mediaDevices.getUserMedia(localStreamConstraints)
            .then(gotStream)
            .catch(function(e) {
                console.log('e=>', e)
                setIsError(true)
            });
        return function cleanup() {
            if (localRefStream) {
                localRefStream.getTracks().forEach(function(track) {
                    track.stop();
                })
            }
            if (_stream) {
                _stream.getTracks().forEach(function(track) {
                    track.stop();
                })
            }
        }
    },[])
  
    return (
        <div
            style={{height: '100vh'}}
        >  
            <Header />
            <div 
                style={{
                    maxHeight: '100vh',
                    height: 'calc(100vh - 200px)',
                    display: 'flex',
                    justifyContent:'center',
                    padding:'0px 10px'
                }}
            >
                <section
                    style={{
                        height:'100%',
                        width: '100%',
                        margin:'0 auto',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'space-around'
                    }}
                >
                    {
                        isError &&
                        <>
                           <p>
                               No fue posible conectarse con su camara. 
                               Por favor, verifique que el periferico este correctamente
                               instalado y que posee los permisos necesarios para ser usado
                               por el Navegador.
                           </p>

                        </>
                    }
                    {
                        inspection && 
                        <p style={{textAlign:'center', marginTop: '0px'}}>{inspection.name}</p>
                    }

                    <video 
                        ref={localVideo} 
                        id="localVideo" 
                        style={{
                            objectFit:'cover',
                            objectPosition:'center',
                            height:"calc(100% - 150px)",
                            width:"auto"
                        }}
                        autoPlay 
                        playsInline  
                        muted={true}
                        >    
                    </video>
                    {
                        !isError &&
                        <>
                            <p>Debe permitir utilizar la camara y el microfono del dispositivo para poder continuar</p>
                            <Button 
                                text={'Unirse'}
                                clickAction={()=>history.push('/call/room')}
                            />
                        </>
                    }
                </section>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
      inspection: state.inspection
    }
}

export default connect(
    mapStateToProps,
    null
)(VideoConfJoin);