const serverUrl = (window._env_ ? window._env_.REACT_APP_INSPECTION_API_URL : false) || process.env.REACT_APP_INSPECTION_API_URL; 
const carInformationUrl = (window._env_ ? window._env_.REACT_APP_COTIZADOR_API_URL : false) || process.env.REACT_APP_COTIZADOR_API_URL;
const streamUrl = (window._env_ ? window._env_.REACT_APP_STREAM_URL : false) || process.env.REACT_APP_STREAM_URL;
const envApp = (window._env_ ? window._env_.REACT_APP_ENV_APP : false) || process.env.REACT_APP_ENV_APP;
const detectorApi = (window._env_ ? window._env_.REACT_APP_DETECTOR_API : false) || process.env.REACT_APP_DETECTOR_API;
const inspectorApi = (window._env_ ? window._env_.REACT_APP_INSPECTOR_API : false) || process.env.REACT_APP_INSPECTOR_API;

export const config = {
  "API_URL": serverUrl, 
  "SERVER_URL": serverUrl + "v1", 
  "CAR_INFORMATION_URL": carInformationUrl + "v1",
  "STREAM_URL": streamUrl,
  "ENV_APP": envApp,
  "DETECTOR_API": detectorApi,
  "INSPECTOR_API": inspectorApi
  
}