import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { Container } from '@material-ui/core';
import Portrait from '../portrait';
import Button from '../Button/ButtonBorder';
import { literals } from '../../utils/constants';
import HandObject from '../../assets/images/objects_on_hand.jpg';

const StepComponent = () => {
  let history = useHistory();

  const handleClick = () => {
    history.push('/3');
  }

  return (
    <>
      <Container fixed>
        <Portrait 
          image={HandObject}
          text={literals.introductionSecondStepText}
        />
      </Container>
      <div className="button-container">
        <Button 
          text="SIGUIENTE"
          clickAction={handleClick}
        />
      </div>
    </>
  );
}

export default StepComponent;