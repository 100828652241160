import React from 'react';
import {
  connect
} from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CameraMini from '../../assets/images/image_placeholder.png';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});


const PhotoCardComponent = ({ id, name, clickAction, image }) => {

    return (
        
        <div 
            onClick={clickAction}
            style={{
                position:'relative',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                flexDirection: 'column',
                width:'125px', 
                height:'125px', 
                border:'1px dotted gray',
                cursor:'pointer',
                margin:'10px 0px'
            }}
        >
            {
              image ?
              <img src={image} height="45px" width="auto" alt={'taken with camera'} /> :
              <img src={CameraMini} height="45px" width="auto" alt={'camera'} />
            }
            <p style={{
                margin: 0,
                marginTop: 10,
                color:'gray', 
                fontWeight: 400,
                textAlign:'center'
              }}
            >{name}</p>
        </div>
        
    );

};

const mapStateToProps = (state) => {
  return {
    date: state.date,
    time: state.time
  }
}

export default connect(
  mapStateToProps,
  null
)(PhotoCardComponent);