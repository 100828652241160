import React from 'react';
import {connect} from 'react-redux';
import CameraMini from '../../assets/images/mini_camera.png';
import CameraMiniFailed from '../../assets/images/mini_camera_must.png';

import CameraObligatorio from '../../assets/images/campo_obligatorio.svg';
import CameraMiniSuccess from '../../assets/images/camara_damageless.svg';
import CameraAbolladura from '../../assets/images/camara_abolladura.svg';
import CameraRayon from '../../assets/images/camara_rayon.svg';
import CameraOtro from '../../assets/images/camara_otro.svg';

import { saveId } from '../../actions';
import 'react-html5-camera-photo/build/css/index.css';

const PhotoCardComponent = ({ 
  id, 
  isEmptyValidation = null,
  savingId, 
  text="here goes a text", 
  open, 
  close, 
  photos 
}) => { 

  const handleOnClick = () => {
    savingId(id);
    open(1);
  }

  const cameraImageForStatus = (status) => {
    switch (status) {
      case 'no_damage':
        return CameraMiniSuccess;
      
      case 'scratch':
        return CameraRayon;
      
      case 'hit':
        return CameraAbolladura;
      
      case 'other':
        return CameraOtro;
      
      case 'must':
        return CameraObligatorio;
        
      default:
        return CameraMini;
    }
  }

  const backgroundColorForStatus = (status) => {
    switch (status) {
      case 'no_damage':
        
        return '#E2F5EA';
      
      case 'scratch':
      
        return '#FCEBDB';
      
      case 'hit':
    
        return 'rgba(235, 87, 87, 0.2)';
      
      case 'other':
  
        return 'rgba(235, 87, 87, 0.2)';
        
      default:
          return '';
    }
  }

  return (
    <div 
      onClick={handleOnClick}
      style={{
        width:'90%',
        height:'100px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderTopLeftRadius: (id ===0 ? '15px':'0px'),
        borderTopRightRadius: (id ===2 ? '15px':'0px'),
        borderBottomLeftRadius: (id ===7 ? '15px':'0px'),
        borderBottomRightRadius: (id ===9 ? '15px':'0px'),
        backgroundColor: (
          (photos[id].images && photos[id].images.length > 0) && backgroundColorForStatus(photos[id].status)
        )
      }}
    >
      {
        (photos[id].status ?
        <img src={cameraImageForStatus(photos[id].status)} height="20px" width="auto" /> : 
        <img src={cameraImageForStatus(!isEmptyValidation ?  'must' : 'none')} height="20px" width="auto" /> )
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    photos: state.photos
  }
}

const mapDispatchToProps = dispatch => {
  return {
    savingId: (id, data) => dispatch(saveId(id, data))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoCardComponent);