import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@material-ui/core/MobileStepper';
import Container from '@material-ui/core/Container';
import Button from '../Button/ButtonBorder';
import Portrait from '../portrait';
import { literals } from '../../utils/constants';
import LuzNatural from '../../assets/images/natural_light.jpg';
import Limpieza from '../../assets/images/cleaness.jpg';
import Conexion from '../../assets/images/conexion.jpg';
import '../../styles/introduction.css';
import '../../styles/step.css';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
});

const StepComponent = ({open, close}) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleClick = () => {
    history.push('/autoinspection');
  }

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <Container fixed>
        <SwipeableViews
          onChangeIndex={handleStepChange}
        >
          <div>
            <Portrait 
              text={literals.introductionNaturalLight}
              image={LuzNatural}
            />
          </div>
          <div>
            <Portrait 
              text={literals.introductionCleanliness}
              image={Limpieza}
            />
          </div>
          <div>
            <Portrait 
              text={literals.introductionConnectivity}
              image={Conexion}
            />
          </div>
        </SwipeableViews>
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={activeStep}
          className={classes.root}
        />
      </Container>
      <div className="button-container">
        <Button 
          text="ENTENDIDO"
          clickAction={handleClick}
        />
      </div>
    </>
  );
}

export default StepComponent;