import React, { useEffect, useState } from 'react';
import { config } from '../../config';
import { updateTracking, updateStep } from '../../services';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import GuideSelector from '../Layout/guideSelector';
import { connect } from 'react-redux';
import Header from '../header';
import Grid from '@material-ui/core/Grid';
import ButtonBorder from '../Button/ButtonBorder';
import PhotoCard from '../photoCard';
import ErrorIcon from '@material-ui/icons/Error';
import CarPhoto from '../../assets/images/car_photo.png';
import CarImage from '../../assets/images/car-image.svg';
import { getGuideInfo } from '../../services';
import { setGridGuide } from '../../actions'

const useStyles = makeStyles({
  root: {
    maxWidth: '80vw',
    flexGrow: 1,
    backgroundColor: 'transparent'
  },
  progress: {
    width: '100%',
  },
  barColorPrimary: {
    backgroundColor: 'green',
    color: 'green'
  },
  extraPadding: {
    padding: '0px 25px'
  }
});

const StepComponent = ({
  photos, 
  save, 
  open, 
  close, 
  fnNextStep,
  date,
  time,
  url_id,
  guide,
  savingGridGuide,
  photosByGuide
}) => {
  const classes = useStyles();
  const [_photos, setPhoto] = useState([
    false, false, false, false, false, false, false, false, false, false
  ]);
  const [missingPhoto, setIsMissingPhoto] = useState(false);
  const [photosSlide, setPhotosSlide] = useState(false);
  const [guideInfo, setGuideInfo] = useState(null)
  const [riskInfo, setRiskInfo] = useState(null)
  const [externalType, setExternalType] = useState(null)
  const [externalModel, setExternalModel] = useState(null)
  const [totalPhotos, setTotalPhotos] = useState(1);
  
  useEffect(()=>{

    updateTracking('Web App: Alta de fotos exterior', url_id, 4);
    updateStep(url_id, 4);

    const getGuideInfoAsync = async (temporaryId) => {

      if (temporaryId) {
        const guideInfoResponse = await getGuideInfo(temporaryId);
        savingGridGuide(guideInfoResponse?.data);
        setGuideInfo(guideInfoResponse?.data);   
        
        if ( guideInfoResponse?.data ) {
          const exter = guideInfoResponse?.data?.photos?.find( el => el.name.trim() === 'Externa');
          setTotalPhotos(exter.related.length);
          setExternalType(exter.type); 
          setExternalModel(exter.model);
  
        }
        setRiskInfo(guideInfoResponse.data?.risk?.name);

      } else {
        alert('No hay una guia asignada a esta inspeccion.');
      }
      
    };

    getGuideInfoAsync(url_id);
    
  },[]);

  const handleOnClick = () => {

    console.log('config.ENV_APP===>', config.ENV_APP, externalType.toLowerCase())
    console.log('photos before send===>', photosByGuide, photos, totalPhotos)

    if ( config.ENV_APP === 'PROD') {

      if ( externalType.toLowerCase() !== 'card' ) { 
        let j = 0;
        let newPhotosState = [];
        for (let i = 0; i < photos.length; i++) {
          if (photos[i].images === undefined || photos[i].images.length === 0) {
            newPhotosState[i] = true;
            setIsMissingPhoto(true)
          } else {
            newPhotosState[i] = false;  
            j = j + 1;
            if (j >= totalPhotos ) {
              fnNextStep(3);
            }
          }
        }
      } else {

        if ( externalType.toLowerCase() === 'card' && photosByGuide.get('Externa')) {
            if ( totalPhotos === photosByGuide.get('Externa').length) {
              fnNextStep(3);
            } else {
              setIsMissingPhoto(true)
            }
        } else {
          setIsMissingPhoto(true);
        }

      }


    } else {
      
      if ( externalType.toLowerCase() !== 'card' ) { 
        let j = 0;
        let newPhotosState = [];
        for (let i = 0; i < photos.length; i++) {
          if(photos[i].images === undefined || photos[i].images.length === 0){
            newPhotosState[i] = true;
            setIsMissingPhoto(true)
          } else {
            newPhotosState[i] = false;
            j++;
            if (j > 1) {
              console.log('entro if step')
              fnNextStep(3);
            }
          }
        }
      }
  
      if ( externalType.toLowerCase() === 'card' && photosByGuide.get('Externa')) {
          if ( 1 < photosByGuide.get('Externa').length) {
            console.log('entro if step')
            fnNextStep(3);
          } else {
            setIsMissingPhoto(true)
          }
      } else {
        setIsMissingPhoto(true);
      }

    }


  }

  const nextScreenPhotoSlide = () => {
    close(0);
  }

  return (
    <>
      <Header
        text={[date, time]}
        model={2}
      />
      <div className="stepper-container">
        <MobileStepper
          variant="progress"
          steps={5}
          position="static"
          activeStep={2}
          classes={{root: classes.root, progress: classes.progress}}
        />
        <span className="steps-count">{`2/4`}</span>
      </div>
      <div 
        style={{
          display:'flex', 
          alignItems:'center', 
          justifyContent:(missingPhoto ? 'space-between' : 'flex-start'),
          paddingLeft: '20px',
          paddingRight: '20px'
        }}
      >
        <h3 style={{
          color: '#141B6A',
          fontWeight:'400',
          margin: '15px 0px'
        }}>Fotos Exterior</h3>
        {
          missingPhoto && 
          <span style={{color:'red', display:'flex', alignItems:'center'}}>
            <ErrorIcon color='error' style={{ marginRight: '2px' }}/>
            { config.ENV_APP === 'PROD' ? 'Campo Obligatorio' : 'Favor tomar al menos 2 fotos' }
          </span>
        }
      </div>    
      {
        !photosSlide &&
        <div style={{padding: '0px 20px'}}>
          <div
            style={{
              margin: 'auto',
              position: 'relative'
            }}
          >
            {
                riskInfo && riskInfo === 'Auto' && externalType && externalType.toLowerCase() !== 'card' &&
                <div style={{
                  position: "absolute",
                  left: '50%',
                  top: '45%',
                  transform: 'translateX(-50%) translateY(-50%)'
                }}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <img src={CarImage} style={{height:"290px"}} alt='car' loading="lazy" />
                    </Grid>
                  </Grid>
                </div>
            }     
            {
              riskInfo && externalType && externalType.toLowerCase() === 'card' &&
                <GuideSelector 
                  data={guideInfo}
                  guide={riskInfo.toLowerCase()} 
                  nextScreenPhotoSlide={nextScreenPhotoSlide}
                />
            }
            <Grid container spacing={0}>
              {/* { 
                !riskInfo && 
                  <p>Loading</p>
              } */}
              {
                externalType && externalType.toLowerCase() === 'image' &&
                <>
                  <Grid item xs={4} sm={4}>
                    <div
                      style={{
                        border: '2px dotted #C8B4F3',
                        borderTopLeftRadius: '15px',
                        borderRight: 'unset',
                        borderBottom: 'unset',
                        width:'100%',
                        height:'180px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        padding: '3px 0px 3px 0px',
                      }}
                    >
                      <PhotoCard  
                        open={open} 
                        close={nextScreenPhotoSlide} 
                        id={0}
                        text={'Frontal Lateral Izquierda'}
                        isEmptyValidation={_photos[0]}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div
                      style={{
                        border: '2px dotted #C8B4F3',
                        borderRight: 'unset',
                        borderBottom: 'unset',
                        width:'100%',
                        height:'180px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        padding: '3px 0px 0px 0px'
                      }}
                    >
                      <PhotoCard  
                        open={open} 
                        close={nextScreenPhotoSlide} 
                        id={1}
                        text={'Frontal'}
                        isEmptyValidation={_photos[1]}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div
                      style={{
                        border: '2px dotted #C8B4F3',
                        borderTopRightRadius: '15px',
                        borderBottom: 'unset',
                        width:'100%',
                        height:'180px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        padding: '3px 0px 0px 0px'
                      }}
                    >
                      <PhotoCard  
                        open={open} 
                        close={nextScreenPhotoSlide} 
                        id={2}
                        text={'Frontal Lateral Derecha'}
                        isEmptyValidation={_photos[2]}
                      />
                    </div>
                  </Grid>
                  
                  <Grid item xs={4} sm={4}>
                    <div
                      style={{
                        border: '2px dotted #C8B4F3',
                        borderBottom: 'unset',
                        borderRight: 'unset',
                        width:'100%',
                        height:'220px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        padding: '3px 0px 3px 0px'
                      }}
                    >
                      <PhotoCard  
                        open={open} 
                        close={close} 
                        id={3}
                        text={'Frontal'}
                        isEmptyValidation={_photos[3]}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div
                      style={{
                        border: '2px dotted #C8B4F3',
                        borderRight: 'unset',
                        borderBottom: 'unset',
                        width:'100%',
                        height:'220px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                      }}
                    >
                    <img src={CarImage} alt='car' style={{height:"290px"}} loading="lazy" />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div
                      style={{
                        border: '2px dotted #C8B4F3',
                        borderBottom: 'unset',
                        width:'100%',
                        height:'220px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        padding: '3px 0px 3px 0px'
                      }}
                    >
                      <PhotoCard  
                        open={open} 
                        close={close} 
                        id={4}
                        text={'Frontal'}
                        isEmptyValidation={_photos[4]}
                      />
                    </div>
                  </Grid>
                  
                  {

                    externalModel !== "8" &&
                    <>
                      <Grid item xs={4} sm={4}>
                        <div
                          style={{
                            border: '2px dotted #C8B4F3',
                            borderBottom: 'unset',
                            width:'100%',
                            height:'180px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            padding: '3px 0px 3px 0px'
                          }}
                        >
                          <PhotoCard  
                            open={open} 
                            close={close} 
                            id={5}
                            text={'Frontal'}
                            isEmptyValidation={_photos[5]}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <div
                          style={{
                            width:'100%',
                            height:'180px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center'
                          }}
                        >
                        </div>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <div
                          style={{
                            border: '2px dotted #C8B4F3',
                            borderBottom: 'unset',
                            width:'100%',
                            height:'180px',
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            padding: '3px 0px 3px 0px'
                          }}
                        >
                          <PhotoCard  
                            open={open} 
                            close={close} 
                            id={6}
                            text={'Frontal'}
                            isEmptyValidation={_photos[6]}
                          />
                        </div>
                      </Grid>
                      
                    </>
                  }
                  <Grid item xs={4} sm={4}>
                    <div
                      style={{
                        border: '2px dotted #C8B4F3',
                        borderBottomLeftRadius: '15px',
                        borderRight: 'unset',
                        width:'100%',
                        height:'180px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        padding: '3px 0px 3px 0px'
                      }}
                    >
                      <PhotoCard  
                        open={open} 
                        close={close} 
                        id={7}
                        text={'Frontal'}
                        isEmptyValidation={_photos[7]}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div
                      style={{
                        border: '2px dotted #C8B4F3',
                        borderRight: 'unset',
                        width:'100%',
                        height:'180px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        padding: '3px 0px 3px 0px'
                      }}
                    >
                      <PhotoCard  
                        open={open} 
                        close={close} 
                        id={8}
                        text={'Frontal'}
                        isEmptyValidation={_photos[8]}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <div
                      style={{
                        border: '2px dotted #C8B4F3',
                        borderBottomRightRadius: '15px',
                        width:'100%',
                        height:'180px',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        padding: '3px 0px 3px 0px'
                      }}
                    >
                      <PhotoCard  
                        open={open} 
                        close={close} 
                        id={9}
                        text={'Frontal'}
                        isEmptyValidation={_photos[9]}
                      />
                    </div>
                  </Grid>  
                </>
              }           
              <Grid item xs={12} sm={12}>
                <div style={{marginTop:'10px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <ButtonBorder
                      clickAction={handleOnClick}
                      text={'SIGUIENTE'}
                    />
                </div>
              </Grid>
           </Grid>  
          </div>
        </div>
      }         
    </>
  );
}

const mapStateToProps = (state) => {
  return { 
    photos: state.photos,
    date: state.date,
    time: state.time ,
    url_id: state.url_id,
    guide: state.gridGuide,
    photosByGuide: state.photosByGuide,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    savingGridGuide: guide => {
      dispatch(setGridGuide(guide))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepComponent);