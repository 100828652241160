import React, { useState } from "react";
import { connect } from 'react-redux';
import PhotoCard from '../photoCard';
import PhotoCardComponent from '../photoCardComp';
import CameraComponent from '../cameraComp';
import { setPhoto } from '../../actions';
import { uploadPhotoFile, updateTracking } from '../../services';
import { composeFormDataGuideImg, bytesArrayOpt } from '../../utils';
const SECTION_ID = 'Externa';

export const gridPhotosFromGuide = ( guideInfo ) => {

    if ( !guideInfo ) return <>loading...</>;
    if ( guideInfo.length === 0 ) return <div><p>No Photos Available. Check your photo's Guide. </p><br /></div>
    console.log('guideInfo.photos=>', guideInfo.photos)
    return (

        guideInfo.photos.map(
            ({ name, related }) => 
                {

                    if ( name.trim() === 'Externa' || name === ' Externa' ) {
                        return (
                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                {
                                    related.length > 0 &&
                                    <>
                                        <DisplayGridPhotosInRightOrder
                                            id={name} 
                                            photoList={related}
                                        />
                                    </>
                                }
                            </div>
                        )
                    }
                    return <></>
                }
        )
    )
}
  
const DisplayGridPhotosInRightOrderCon = ({ id, photosByGuide, photoList, settingPhoto, savingPhotoAtStore, url_id }) => {

    const [openCamera, setOpenCamera] = useState(false);
    const [DataToPhoto, setDataToPhoto] = useState(null);

    const handleStoragePhoto = async (photo) => {
        savingPhotoAtStore(DataToPhoto.name, DataToPhoto.position, photo)
        const byteArrays = bytesArrayOpt(photo);
        const formDataObj = composeFormDataGuideImg('image/jpeg', byteArrays, url_id, DataToPhoto.name, SECTION_ID, DataToPhoto.position);
        const res = await uploadPhotoFile(formDataObj);
        console.log('res=>', res)
    }

    const getPhotoDataFromPhotosGuide = ( photoName ) => {
        const info = photosByGuide.get(SECTION_ID) ? photosByGuide.get(SECTION_ID).filter( ({ name }) => name === photoName || name === ` ${photoName}` ) : null;
        console.log('data inside function=>', photosByGuide, SECTION_ID, photoName, info)
        if ( info && info.length > 0) return info[0].photo
        return null;
    }
  
    const handleClickPhoto = (id, name, position, fnStorage) => { 
        console.log('handleClickPhoto=>', id, name, position)
        fnStorage(id, name, position);
        setOpenCamera( true );
    };

    return (
        <div style={{display:'flex', justifyContent:'space-evenly', flexWrap:'wrap', width: '100%'}}>
            {
                false && photoList.map( 
                    ({ name }) => 
                        <span 
                            style={{
                                width:'125px', 
                                height:'125px', 
                                display: 'inline-block',
                                border: '2px dotted #C8B4F3',
                                margin: 5
                            }}
                        >
                            <p style={{textAlign:'center', margin:0, color:'rgb(20, 27, 106)', fontWeight:100}}>{name}</p>
                            <PhotoCard 
                                id="1"
                                isEmptyValidation={null}
                                savingId={()=>{}} 
                                text="here goes a text"
                                open={()=>{}} 
                                close={()=>{}}  
                            />
                        </span>
                )
            }
            {
                photoList && photoList.map( 
                    ({ name, position }) =>     
                        <PhotoCardComponent 
                            name={name}
                            id={name.split(' ').join('')}
                            clickAction={()=>{ setDataToPhoto({name, position, id: name.split(' ').join('')}); setOpenCamera(true) }}
                            image={
                                getPhotoDataFromPhotosGuide(name)
                            }
                        />
                )
            }
            <CameraComponent 
                storagePhoto={handleStoragePhoto}
                openExter={openCamera}
                afterPhotoFn={()=>setOpenCamera(false)}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      url_id: state.url_id,
      photosByGuide: state.photosByGuide,
      wholeState: state
    }
}
  
const mapDispatchToProps = dispatch => {
    return {
            settingPhoto: photoInfo => { dispatch(setPhoto(photoInfo)) },
            savingPhotoAtStore: (name, position, photo) => dispatch(setPhoto(SECTION_ID, name, position, photo))
    }
}

export const DisplayGridPhotosInRightOrder = connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplayGridPhotosInRightOrderCon);

const GuideSelector = ({ guideInfo }) => {

    return (
        <>
            {gridPhotosFromGuide( guideInfo )}
        </>
    );
}

export default GuideSelector;