import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import socketIOClient from "socket.io-client";
import { config } from '../../config';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import FooterCallOptions from './components/footerCallOptions';
import '../../styles/success.css';

const useStyles = makeStyles({
  root: {
    maxWidth: '70vw',
    flexGrow: 1,
    backgroundColor: 'transparent'
  },
  progress: {
    width: '100%'
  }
});

const VideoChatPage = ({ _room, enabledAudio }) => {
  
    //Defining some global utility variables
    let isChannelReady = false;
    let isInitiator = false;
    let isStarted = false;
    let localStream;
    let pc;
    let remoteStream;
    let turnReady;

    const history = useHistory();
    const localVideo = useRef(null);
    const remoteVideo = useRef(null);
    const canvasRef = useRef(null);
    const [_isInitiator, setIsInitiator] = useState(false);
    const [isRoomCreated, setIsRoomCreated] = useState(false);
    const [room, setRoom] = useState(_room);
    const [refLocalStream, setLocalStream] = useState(null);
    const [shouldFaceUser, setShouldFaceUser] = useState(true)
    const [frontCamera, setFrontCamera] = useState();
    const [backCamera, setBackCamera] = useState();
    const [peerConnection, setPeerConnection] = useState(null)
    const [localStreamConstraints, setLocalStreamConstraints] = useState(
        {
            audio: true,
            video: { 
              facingMode: "user",
              width: {
                ideal: 640
              },
              height:{
                ideal: 400
              }
            }
        }
    );

    const gotStream = (stream) => {
      console.log('Adding local stream.');
      localStream = stream;
      setLocalStream(localStream)
      console.log('localVideo=>', localVideo)
      localVideo.current.srcObject = stream;
      sendMessage('got user media', room);
      console.log('_isInitiator=>', _isInitiator, isInitiator);
      if (isInitiator && !isRoomCreated) {
        maybeStart();
      }
      
    }

    const updatedGotStream = (stream) => {

      localStream = stream;
      setLocalStream(stream)
      let videoTrack = stream.getVideoTracks()[0];
      localVideo.current.srcObject = null;
      localVideo.current.srcObject = stream;
      localVideo.current.play();

      stream.getVideoTracks().forEach(function(track) {
        if ( peerConnection && peerConnection.getSenders() ) {
          let sender = peerConnection.getSenders().find(function(s) {
            console.log('track=>', track)
            console.log('s=>', s.track)
            console.log()
            return s.track.kind == track.kind;
          });
          console.log('sender=>', sender)
          sender.replaceTrack(track);
        }
      });
      // if ( peerConnection ) {
      // peerConnection.addStream(stream);
      // }
      sendMessage('got user media', room)
      if (isInitiator && !isRoomCreated) {
        maybeStart();
      }
    }

    useEffect(() => {
      const audioTrack = refLocalStream?.getTracks().filter(
        track => track.kind === 'audio'
      )
      if (audioTrack) {
        console.log('enabledAudio=>', audioTrack);
        audioTrack[0].enabled = enabledAudio;
      }
    },[enabledAudio]);

    useEffect(() => {
      const tracks = refLocalStream?.getTracks() || [];
      tracks.forEach(track => {
        if (track.kind !== 'audio') track.stop()
      });
        
        let _localStreamConstraints;
        if (shouldFaceUser) {  
          _localStreamConstraints = {
            audio: true,
            video: { 
              deviceId: {
                exact: frontCamera
              },
              width: {
                ideal: 640
              },
              height:{
                ideal: 400
              } 
            }
          }
        } else {
          _localStreamConstraints = {
            audio: true,
            video: { 
              deviceId: {exact : backCamera},
              width: {
                ideal: 640
              },
              height:{
                ideal: 400
              } 
            }
          }
        }
        
        navigator.mediaDevices.getUserMedia(_localStreamConstraints)
            .then(updatedGotStream)
            .catch(function(e) {
                console.log('e=>', e)
                
            });
    },[shouldFaceUser])

    //const socket = socketIOClient("https://test-inspection-api.iconnectance.com/");
    console.log('config.API_URL=>', config.API_URL)
    const socket = socketIOClient(config.API_URL);

    const sendMessage = (message) => {
        console.log('Client sending message: ', message, room);
        socket.emit('message', message, room);
    }

    //If initiator, create the peer connection
    const maybeStart = () => {
        if (!isStarted && typeof localStream !== 'undefined' && isChannelReady) {
            const _pc = createPeerConnection();
            _pc.addStream(localStream);
            setPeerConnection(_pc);
            isStarted = true;
            if (isInitiator) {
              doCall();
            }
        }
    }

    const createPeerConnection = () => {
      var pcConfig = {
        iceServers: [
          {   
            urls: [ "stun:us-turn6.xirsys.com" ]
          }, 
          {   
            username: "xxaxKPCI4ZM2JjGhbZ4RiUckVqo5BmwNmlv7fFmelr58ANmzq26WTDI1l6os-mQwAAAAAGBgre1Ec0VzdGViYW4=",   
            credential: "2ee7583c-8fe2-11eb-9bc0-0242ac140004",   
            urls: ["turn:us-turn6.xirsys.com:80?transport=udp"]
          }
        ]
      };

        try {
          pc = new RTCPeerConnection(pcConfig);
          pc.onicecandidate = handleIceCandidate;
          pc.onaddstream = handleRemoteStreamAdded;
          pc.onremovestream = handleRemoteStreamRemoved;
          setPeerConnection(pc);
          return pc;
        } catch (e) {
          console.log('Failed to create PeerConnection, exception: ' + e.message);
          return;
        }
    }

    const handleIceCandidate = (event) => {
        if (event.candidate) {
          sendMessage({
            type: 'candidate',
            label: event.candidate.sdpMLineIndex,
            id: event.candidate.sdpMid,
            candidate: event.candidate.candidate
          }, room);
        } else {
          console.log('End of candidates.');
        }
    }
    
    const handleCreateOfferError = (event) => {
        console.log('createOffer() error: ', event);
    }
    
    const doCall = () => {
        console.log('Sending offer to peer');
        pc.createOffer(setLocalAndSendMessage, handleCreateOfferError);
        setPeerConnection(pc);
    }
    
    const doAnswer = () => {
        console.log('Sending answer to peer.');
        console.log('in doAnswer pc=>', pc);
        console.log('in doAnswer peerConnection=>', peerConnection);
        pc.createAnswer().then(
            setLocalAndSendMessage,
            onCreateSessionDescriptionError
        );
        setPeerConnection(pc);
    }
    
    const setLocalAndSendMessage = (sessionDescription) => {
        pc.setLocalDescription(sessionDescription);
        console.log('setLocalAndSendMessage sending message', sessionDescription);
        sendMessage(sessionDescription, room);
        setPeerConnection(pc);
    }
    
    const onCreateSessionDescriptionError = (error) => {
        console.log('Failed to create session description: ' + error.toString());
        //trace('Failed to create session description: ' + error.toString());
    }

    const handleRemoteStreamAdded = (event) => {
        console.log('Remote stream added.');
        remoteStream = event.stream;
        remoteVideo.current.srcObject = remoteStream;
    }
      
    const handleRemoteStreamRemoved = (event) => {
        console.log('Remote stream removed. Event: ', event);
    }
      
    const hangup = () => {
        console.log('Hanging up.');
        console.log('Hanging up=>', refLocalStream.getTracks());
        refLocalStream.getTracks()[0].stop();
        refLocalStream.getTracks()[1].stop();
        sendMessage('bye', room);
        socket.emit('bye', room);
        localVideo.current.pause();
        refLocalStream.getTracks().forEach(function(track) {
          track.stop();
        })
        //videoTrack.stop();
        history.push('/call/summary')
        stop();
    }
    
    const handleRemoteHangup = () => {
        console.log('Session terminated.');
        stop();
        isInitiator = false;
        setIsInitiator(false);
        
    }
    
    const stop = () => {
        isStarted = false;
        console.log('at stop pc=>', pc)
        console.log('at stop peerConnection=>', peerConnection)
        if ( peerConnection ) {
          peerConnection.close();
        }
        if (pc) {
          pc.close();
          pc = null;
        }
    }

    useEffect( () => {

        
        if (room !== '') {
            socket.emit('create or join', room);
            console.log('Attempted to create or  join room', room);
        }
        socket.on('created', function(room) {
            console.log('Created room ' + room);
            isInitiator = true;
            setIsRoomCreated(true);
            setIsInitiator(true);
        });

        socket.on('join', function (room){
            console.log('Another peer made a request to join room ' + room);
            console.log('This peer is the initiator of room ' + room + '!');
            isChannelReady = true;
        });
        
        socket.on('joined', function(room) {
            console.log('joined: ' + room);
            isChannelReady = true;
        });

        socket.on('message', function(message, room) {
            console.log('Client received message:', message,  room);
            if (message === 'got user media') {
              maybeStart();
            } else if (message.type === 'offer') {
              console.log('_isInitiator=>', _isInitiator)
              console.log('isInitiator=>', isInitiator)
              if (!isInitiator && !isStarted) {
                maybeStart();
              }
              console.log('before do answer pc is=>', pc);
              console.log('before do answer peerConnec is=>', peerConnection);
              pc.setRemoteDescription(new RTCSessionDescription(message));
              doAnswer();
            } else if (message.type === 'answer' && isStarted) {
              pc.setRemoteDescription(new RTCSessionDescription(message));
            } else if (message.type === 'candidate' && isStarted) {
              var candidate = new RTCIceCandidate({
                sdpMLineIndex: message.label,
                candidate: message.candidate
              });
              pc.addIceCandidate(candidate);
            } else if (message === 'bye' && isStarted) {
              handleRemoteHangup();
            } 
        });

        let supports = navigator.mediaDevices.getSupportedConstraints();
        if( supports['facingMode'] === true ) {
            // flipBtn.disabled = false;
        }

        navigator.mediaDevices.enumerateDevices()
          .then(function(devices) {
            devices.forEach(function(device) {
              console.log(device.kind + ": " + device.label + " id = " + device.deviceId)
              if (device.label.toLowerCase().includes('camera') && device.label.toLowerCase().includes('front')) {
                setFrontCamera(device.deviceId);
              }
              if (device.label.toLowerCase().includes('camera') && device.label.toLowerCase().includes('back')) {
                setBackCamera(device.deviceId);
              }
            });
          })
          .catch(function(err) {
            console.log(err.name + ": " + err.message);
          });
        
        //browser.history.deleteUrl('/call/join')
        window.history.pushState({}, '', '/call');

        // close window event
        window.addEventListener("beforeunload", (ev) => 
        {  
            ev.preventDefault();
            console.log('sitio que se va a cerrar!!');
            return ev.returnValue = 'Are you sure you want to close? Your call will be lost';
        });
    },[])

  const classes = useStyles();
  return (
    <>
    <div style={{
        position:'absolute',
        top: 0,
        left: 0,
        zIndex: 10
    }}>
        <video ref={remoteVideo} id="remoteVideo" className="" autoPlay playsInline  width="25%" height="auto"></video>
    </div>
    <div style={{
        zIndex: 1
    }}>
      <video 
        ref={localVideo} 
        id="localVideo" 
        className="" autoPlay playsInline  
        muted
        style={{objectFit:'cover', width:'100%', height:'85vh'}}></video>
    </div>
      <FooterCallOptions 
        switchCameras={()=>setShouldFaceUser( shouldFaceUser => !shouldFaceUser )}
        hangUpCall={hangup}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    url_id: state.url_id,
    _room: state.room,
    enabledAudio: state.enabledAudio
  }
}

export default connect(
  mapStateToProps,
  null
)(VideoChatPage);