import Axios from 'axios'; 
import { config } from '../config';
import { validate } from '../utils';
import { stepsAutoInspection } from '../utils/constants';

export const getName = async id => {
  const name = await Axios.get(`${config.SERVER_URL}/inspections/temporary/${id}`)
      .then( ({ data })  => {

        if ( parseInt(data.status) === 3 ) {
          return -1;
        }
        
        return data.name +' '+ data.lastname;
      });
  return name;
};

export const getDateAndTime = async id => {
  const name = await Axios.get(`${config.SERVER_URL}/inspections/temporary/${id}`)
      .then( ({ data })  => {
        const tomorrow = new Date(data.date[data.date.length - 1]);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return [tomorrow.getDate()+'/'+(tomorrow.getMonth()+1).toString(), tomorrow.getHours()+':'+tomorrow.getMinutes()];
      });
  return name;
};

export const updateTracking = async (msg, id, status, summary) => {
  console.log('summary', summary)

  const obj = {
    msg,
    status,
    autoInspectionStep: status,
    summary
  }

  const toUpdateJson = JSON.stringify( obj );
  Axios.put(`${config.SERVER_URL}/inspections/temporary/tracking/${id}`, toUpdateJson , {
    headers: {
      'Content-Type': 'application/json'
    }}).then(({ data })=>{
      console.log('data=>', data);
    });
};

export const getTracking = async id => { 
  console.log('id=>', id, validate.isString(id))
  try {
      if ( !validate.isString(id) ) {console.error('not valid link'); throw new Error(`not valid link`); }
      
      const tracking = await Axios.get(`${config.SERVER_URL}/inspections/temporary/${id}`); 
      if ( !validate.isInt(tracking.data.autoInspectionStep) ) {console.error('Resp tracking is not a integer', tracking.data.autoInspectionStep); throw new Error('Parameter is not a integer'); }
      
      return tracking.data.autoInspectionStep;
  } catch ({ message }) {
      console.log('message=>', message)
      if (message === 'Network Error') return stepsAutoInspection.inspectionNetworkError;
      if (message === 'Parameter is not a integer') return stepsAutoInspection.inspectionNotFound;
      if (message === 'not valid link') return stepsAutoInspection.inspectionNotFound;
      return null;
  }
}

export const getInspectionInfo = id => {
  if (id) {
    const inspectionInfo = 
    Axios.get(`${config.SERVER_URL}/inspections/temporary/${id}`)
      .then( ({ data }) => data)
      .catch( e => {
        console.log('error=>', e)
      } )
      ;
    return inspectionInfo;
  }
  return null;
}

export const getGuideInfo = async guideId => {
  
  try {

    const guideInfo = await Axios.get(`${config.SERVER_URL}/guides/temporary/${guideId}`);
    return guideInfo;
    
  } catch (e) {
    console.error('Error at getGuideInfo fn')
    return null;
  }

}

export const getRiskInfo = async riskId => {
  
  try {

    const riskInfo = await Axios.get(`${config.SERVER_URL}/risk/${riskId}`);
    return riskInfo;
    
  } catch (e) {
    console.error('Error at getRiskInfo fn')
    return null;
  }

}

export const uploadPhotoFile = async formDataToUpload => {
  let configRequest = {
    header : {
      'Content-Type' : 'multipart/form-data'
    }
  }

  try { 
    
    const resposeStatus = await Axios.post(`${config.SERVER_URL}/inspections/uploadGuidePhoto`, formDataToUpload, configRequest);
    return resposeStatus;

  } catch (e) {
    console.log()
  }
}

export const updateStep = async (id, autoInspectionStep) => {

  const toUpdateJson = {
    autoInspectionStep
  }

  Axios.put(`${config.SERVER_URL}/inspections/temporary/${id}`, toUpdateJson , {
    headers: {
      'Content-Type': 'application/json'
    }}).then(({ data })=>{
      console.log('data=>', data);
    });
};

export const updateInspection = async (id, data) => {
  Axios.put(`${config.SERVER_URL}/inspections/temporary/detectionSummary/${id}`, data , {
    headers: {
      'Content-Type': 'application/json'
    }}).then(({ data })=>{
      console.log('data=>', data);
    });
}

export const detectorD3API = async (images, id, step, message) => {
  Axios.post(`${config.DETECTOR_API}predictions/d3/v1`, images, {
    headers: {
      'Content-type': 'application/json'
    }}).then(({data}) => {
      updateInspection(id, data)
      return data;
    })
    .catch((e) => {
      console.log('error', e);
    })
};

export const frsAPI = async (data) => {
  const frs = await Axios.post(`${config.INSPECTOR_API}workflow/iac_and_frs/v1`, data, {
    headers: {
      "Content-type": "application/json",
    },
  })
  .then(({data}) => {
    return data;
  }).catch((error) => {
    console.log('error', error)
  });

  return frs;
}


