import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconnectanLogo from '../../../assets/images/iconnectance_logo.png';

const HeaderComponent = ({ 
  text = '', 
  model=1
}) => (
  <header className="header-container">
    <Grid container>
        <Grid item xs={2} sm={10}>
            <div className="center">
            <img src={IconnectanLogo} width="36" height="36" />
            </div>
        </Grid>
        <Grid item xs={10} sm={10}>
            <div className="center">
                <h2>
                    Auto-inspeccion Digital
                </h2>
            </div>
        </Grid>
    </Grid>
  </header>
);

export default HeaderComponent;
