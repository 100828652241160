import React, { useEffect } from 'react';
import {
  connect
} from 'react-redux';
import Header from '../components/header';
import StepOne from '../components/steps/step_1';
import { 
  getDateAndTime, 
  updateTracking, 
  getTracking,
  updateStep,
} from '../services';
import { saveDate, saveStep } from '../actions';
import '../styles/introduction.css';

const IntroductionPage = ({
  id = '',
  savingDate,
  date = '',
  time,
  savingStep
}) => {
  useEffect (()=>{
    const gettingDate = async ()=> {
      const date = await getDateAndTime(id);
      savingDate(date);
    };
    if ( id && id !== '' ) {

      gettingDate();
      updateTracking('Web App: Ingreso a inspección', id, 2);
      updateStep(id, 2);
      savingStep(3);
      getTracking();
    }

  },[]);

  return (
    <div>
      <div className="introduction-container introduction-container-size">
        <Header 
          text={[date, time]}
          model={2}
        />
        <StepOne id={id} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    date: state.date,
    time: state.time
  }
}

const mapDispatchToProps = dispatch => {
  return {
    savingDate: date => {
      dispatch(saveDate(date))
    },
    savingStep: (id) => dispatch(saveStep(id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntroductionPage);