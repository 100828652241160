import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Typography, Grid, Divider } from "@material-ui/core";
import { useStyles } from "./styles";
import { saveId } from "../../actions";
import { getInspectionInfo, updateStep, updateTracking } from "../../services";
import { getBrands, getModels } from "../../services/carServices";
import { getWordSpanish } from "../../utils/aiTerms";

import ButtonBorder from "../Button/ButtonBorder";
import SeverityComponent from "../ReportD3/severityComponent";

const StepComponent = ({ url_id }) => {
  const [inspectionInfo, setInspectionInfo] = useState({
    name: "",
    lastname: "",
    phone: "",
    email: "",
    brand: "",
    model: "",
  });
  const [damagesSummary, setDamagesSummary] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getInspectionValue(url_id);
    updateStep(url_id, 5);
    updateTracking("Web App: Resumen daños", url_id, 7);
  }, []);

  const getInspectionValue = async (idInspection) => {
    const inspectionData = await getInspectionInfo(idInspection);
    const {
      name,
      lastname,
      phone,
      email,
      year,
      brandId,
      modelId,
      detectionSummary,
    } = inspectionData;
    getDetectionSummary(detectionSummary[0].data[0]);
    const brandInspection = await getBrandName(brandId);
    const modelInspection = await getModelName(brandId, modelId);

    setInspectionInfo({
      name,
      lastname,
      phone,
      email,
      year,
      brand: brandInspection,
      model: modelInspection,
      detectionSummary,
    });
  };

  const history = useHistory();

  const getBrandName = async (brandId) => {
    const brands = await getBrands();
    const brandsInspection = brands.filter((brand) => brand._id === brandId);
    return brandsInspection[0].name;
  };

  const getModelName = async (brandId, modelId) => {
    const models = await getModels(brandId);
    const modelsInspection = models.filter((model) => model._id === modelId);
    return modelsInspection[0].name;
  };

  const handlerNext = () => {
    updateTracking("Web App: Alta de fotos interior", url_id, 7);
    history.push("/success");
  };

  const getDetectionSummary = (summary) => {
    setDamagesSummary(summary);
  };

  return (
    <>
      <div style={{ height: "80vh" }}>
        <Grid container className={classes.titleSection}>
          <Grid item xs={12}>
            <Typography variant="h4">Resumen de daños</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ color: "#26ADF8" }}>
              Daño Estimados Iniciales
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.formElStyles}>
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>Marca</strong>
                </span>
                <span>{inspectionInfo.brand}</span>
              </div>
              <br />
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>Modelo</strong>
                </span>
                <span>{inspectionInfo.model}</span>
              </div>
              <br />
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>Año</strong>
                </span>
                <span>{inspectionInfo.year}</span>
              </div>
              <br />
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>Nombre</strong>
                </span>
                <span>{`${inspectionInfo.name} ${inspectionInfo.lastname}`}</span>
              </div>
              <br />
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>Telefono</strong>
                </span>
                <span>{inspectionInfo.phone}</span>
              </div>
              <br />
              <div
                className={classes.blockInfo}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span className={classes.titleInfoMinor}>
                  <strong>Correo</strong>
                </span>
                <span>{inspectionInfo.email}</span>
              </div>
              <br />
            </div>
          </Grid>
          <Grid item xs={12}>
            <span
              style={{
                color: "#19A8F8",
                fontWeight: 700,
                marginBottom: "15px",
              }}
            >
              Detalle de reparaciones
            </span>
          </Grid>
        </Grid>
        {damagesSummary && Object.keys(damagesSummary).length ? (
          <Grid container style={{ textAlign: "center" }}>
            <Grid item xs={3} className={classes.textCenter}>
              <Typography
                variant="caption"
                color="primary"
                className={classes.fontWeight}
              >
                AUTOPARTE
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.textCenter}>
              <Typography
                variant="caption"
                color="primary"
                className={classes.fontWeight}
              >
                DAÑO DETECTADO
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.textCenter}>
              <Typography
                variant="caption"
                color="primary"
                className={classes.fontWeight}
              >
                PORCENTAJE DE DAÑO
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.textCenter}>
              <Typography
                variant="caption"
                color="primary"
                className={classes.fontWeight}
              >
                NIVEL DE DAÑO
              </Typography>
            </Grid>
            <Divider style={{ marginTop: "5px" }} />
          </Grid>
        ) : null}

        {damagesSummary?.damages?.damages?.map((damage) => (
          <Grid
            container
            style={{ marginTop: "25px", textAlign: 'center' }}
          >
            <Grid item xs={3} className={classes.textCenter}>
              <Typography variant="caption">
                {getWordSpanish(
                  damage.damaged_part.split("_")[1]
                )?.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.textCenter}>
              <Typography variant="caption">
                {getWordSpanish(damage.damage_type)?.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.textCenter}>
              <Typography variant="caption">
                {(damage.damage_eval * 100).toFixed(2)}%
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.textCenter}>
              <SeverityComponent severity={damage.damage_severity} />
            </Grid>
            <Divider style={{ marginTop: "5px" }} />
          </Grid>
        ))}

        <div style={{ height: "30vh", position: "relative" }}>
          <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ButtonBorder text={"SIGUIENTE"} clickAction={handlerNext} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    photos: state.photos,
    url_id: state.url_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    savingId: (id, data) => dispatch(saveId(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepComponent);
