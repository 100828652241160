import React, { useState } from "react";
import axios from "axios";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { connect, useDispatch } from "react-redux";
import Header from "../header";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ButtonBorder from "../Button/ButtonBorder";
import ImagePlaceholderEmpty from "../../assets/images/image_placeholder.png";
import PhotoStatus from "../../assets/images/photos_status.png";
import EmptyCircle from "../../assets/images/empty_circle.png";
import NoDamageIcon from "../../assets/images/no_damage_icon.png";
import ScratchIcon from "../../assets/images/scratch_icon.png";
import HitIcon from "../../assets/images/hit_icon.png";
import OtherIcon from "../../assets/images/other_icon.png";
import ImagePlaceholder from "../../assets/images/new_image_icon.png";
import TextField from "@material-ui/core/TextField";
import ErrorIcon from "@material-ui/icons/Error";
import "../../styles/photo_slide.css";
import { saveStatus } from "../../actions";
import { config } from "../../config";
import useFRS from "../../hooks/useFRS";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

const MAP_TYPE_PHOTO = [
  "side_frontal_left",
  "frontal",
  "side_frontal_right",
  "side_left_top",
  "side_right_top",
  "side_left_lower",
  "side_right_lower",
  "side_back_left",
  "back",
  "side_back_right",
  "dashboard",
  "front_card",
  "back_card",
];

const CameraComponent = ({
  id = 0,
  photos,
  url_id,
  close,
  back,
  next,
  date,
  time,
  savingStatus,
}) => {
  const [clicked, setClicked] = useState("none");
  const [comments, setComments] = useState("");
  const [infoInspection, setInfoInspection] = useState(null);
  const [isStatusChoosed, setIsStatusChoosed] = useState(null);

  const { handleFRSImage, setOpenAds, message, variant, loading, openAds } =
    useFRS();

  const handleClickOption = (option) => {
    setClicked(option);
    setIsStatusChoosed(true);
  };

  const handleOnChangeComments = (ev) => {
    setComments(ev.target.value);
  };

  const handleOnClickUpload = () => {
    if (isStatusChoosed !== true) {
      setIsStatusChoosed(false);
      return;
    } else {
      const obj = {
        angle: MAP_TYPE_PHOTO[id],
        id: url_id,
        comments,
        status: clicked,
      };

      savingStatus(id, clicked);

      const toUpdateJson = JSON.stringify(obj);
      axios
        .put(
          `${config.SERVER_URL}/inspections/temporary/photo/${url_id}`,
          toUpdateJson,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(({ data }) => {
          setInfoInspection(data);
          handleFRSImage(data);
        });

    }
  };
  
  const handleAdsResult = () => {
    setOpenAds(false);
    next(0);
  };

  
  

  return (
    <div className="photoslide-container">
      <Header text={[date, time]} model={2} />
      <header
        style={{ display: "flex", alignItems: "center", marginLeft: 20 }}
        onClick={() => next(2)}
      >
        <ArrowBackIosIcon />
        <span style={{ marginLeft: 20 }}>Volver</span>
      </header>
      {
        <>
          <Alert severity={variant} onClose={handleAdsResult}>
            {message}
          </Alert>
          <section className="section-container">
            <h3 className="titles">Fotos</h3>
            <div className="carrousel-status-photos">
              <div className="add-photo-container" onClick={() => next(1)}>
                <img
                  onClick={back}
                  src={ImagePlaceholderEmpty}
                  className="photo-carrousel-like"
                />
              </div>
              {photos[id].images.map((photo) => {
                return (
                  <div
                    style={{
                      height: "50px",
                      width: "50px",
                      background: "url(" + photo.content + ")",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      marginLeft: "5px",
                    }}
                  ></div>
                );
              })}
            </div>
          </section>
          <section className="section-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h3 className="titles">Estado actual</h3>
              <span
                style={{ color: "red", display: "flex", alignItems: "center" }}
              >
                {isStatusChoosed === false && (
                  <>
                    <ErrorIcon color="error" />
                    Campo obligatorio
                  </>
                )}
              </span>
            </div>
            <div className="status-container">
              <div className="no-damage-container">
                <div
                  className={
                    clicked === "no_damage" ? "damage_clicked" : "unclicked"
                  }
                  onClick={() => handleClickOption("no_damage")}
                >
                  {clicked === "no_damage" ? (
                    <img src={NoDamageIcon} />
                  ) : (
                    <img src={EmptyCircle} />
                  )}
                  <span style={{ textTransform: "uppercase" }}>SIN DAñO</span>
                </div>
              </div>
              <div className="scratch-container">
                <div
                  className={
                    clicked === "scratch" ? "scratch_clicked" : "unclicked"
                  }
                  onClick={() => handleClickOption("scratch")}
                >
                  {clicked === "scratch" ? (
                    <img src={ScratchIcon} />
                  ) : (
                    <img src={EmptyCircle} />
                  )}
                  <span>RAYON</span>
                </div>
              </div>
              <div className="hit-container">
                <div
                  className={clicked === "hit" ? "hit_clicked" : "unclicked"}
                  onClick={() => handleClickOption("hit")}
                >
                  {clicked === "hit" ? (
                    <img src={HitIcon} />
                  ) : (
                    <img src={EmptyCircle} />
                  )}
                  <span>ABOLLADURA</span>
                </div>
              </div>
              <div className="other-container">
                <div
                  className={
                    clicked === "other" ? "other_clicked" : "unclicked"
                  }
                  onClick={() => handleClickOption("other")}
                >
                  {clicked === "other" ? (
                    <img src={OtherIcon} />
                  ) : (
                    <img src={EmptyCircle} />
                  )}
                  <span>OTRO</span>
                </div>
              </div>
            </div>
          </section>
          <section className="section-container">
            <h3 className="titles">
              Comentarios <i>(opcional)</i>
            </h3>
            <TextField
              multiline
              rows={6}
              onChange={handleOnChangeComments}
              value={comments}
              variant="outlined"
              fullWidth={true}
            />
          </section>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "20px",
              marginLeft: "20px",
              width: "calc(100% - 40px)",
            }}
          >
            {loading ? (
              <CircularProgress color="primary" size={50} />
            ) : (
              <ButtonBorder
                clickAction={handleOnClickUpload}
                text={"SUBIR"}
                active={openAds}
              />
            )}
          </div>
        </>
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    id: state.id,
    url_id: state.url_id,
    photos: state.photos,
    date: state.date,
    time: state.time,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    savingStatus: (id, status) => dispatch(saveStatus(id, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraComponent);
