import React, { useState } from "react";
import { frsAPI } from "../services";
import { getWordSpanish } from "../utils/aiTerms";

const useFRS = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("");
  const [openAds, setOpenAds] = useState(false);
  const [idPhoto, setIdPhoto] = useState("");
  const [indexImage, setIndexImage] = useState(0);

  const [activateAnalysis, setActivateAnalysis] = useState(false);


  //!SECTION: Esta funcion se usa para activar los mensajes en la evaluacion
  const responseEvaluation = (activate, message, typeMessage) => {
    setLoading(activate);
    setMessage(message);
    setVariant(typeMessage);
  };

  const positionArray = (array) => {
    setIndexImage(array.length - 1);
    return array.length - 1;
  };

  const getIdPhoto = (photo) => {
    const photoSplit = photo.split("/")[3];
    const id = photoSplit.split(".")[0];
    setIdPhoto(id);
  };

  const getPhotosInspection = (data) => {
    const { photos } = data;
    return photos[0][1];
  };

  const handleFRSImage = (data) => {
    const inspectionImages = getPhotosInspection(data);
    const indexPosition = positionArray(inspectionImages);
    const { name, photo } = inspectionImages[indexPosition];
    formatDataFRSModel(photo, name);
  };

  const formatDataFRSModel = async (images, nameSide) => {
    getIdPhoto(images);
    responseEvaluation(
      true,
      "Estamos evaluando las imagenes, espere un momento",
      "info"
    );
    setOpenAds(true);
    const imageForFRS = {
      data: {
        url: images,
      },
    };

    getValidateFRS(imageForFRS, nameSide);
  };

  const getValidateFRS = async (url, nameSide) => {
    const resultFRS = await frsAPI(url);
    validateIAC(resultFRS, nameSide);
  };

  const validateIAC = (data, nameSide) => {
    const {
      predictions: { model_IAC, model_FRS },
    } = data;
    if (model_IAC[0].is_a_car === "true" && Array.isArray(model_FRS)) {
      validateFRS(data.predictions.model_FRS, nameSide);
    } else {
      setActivateAnalysis(true);
      responseEvaluation(
        false,
        "Las imagenes no cumplen con los requisitos para la evaluacion, por favor intente de nuevo",
        "error"
      );
    }
  };

  const validateFRS = (modelFRS, nameSide) => {
    const sideEvaluation = modelFRS[0].car_side.split("-").join("_");
    const side = getWordSpanish(sideEvaluation);
    if (side === nameSide) {
      responseEvaluation(
        false,
        "Las imagenes cumplen con los requisitos para la evaluacion",
        "success"
      );
    } else {
      responseEvaluation(
        false,
        "Las imagen cargada no corresponde con el lado indicado en la opcion, por favor intente de nuevo",
        "error"
      );
    }
  };

  return {
    message,
    variant,
    openAds,
    activateAnalysis,
    loading,
    idPhoto,
    indexImage,
    setOpenAds,
    handleFRSImage,
  };
};

export default useFRS;
