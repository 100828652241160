import React, { useEffect } from 'react';
import {
  connect
} from 'react-redux';
import { Container } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';

import PhotosSlide from '../components/NavModal/photo_slide';
import { savingDate, updateTracking, updateStep } from '../services';

import Header from '../components/header';
import Camera from '../components/camera';
import CarModal from '../assets/images/car_modal.jpg';

import Pencil from '../assets/images/pencil.png';
import PencilDisabled from '../assets/images/pencil_unselected.png';

import StepOne from '../components/inspection/step_1';
import StepTwo from '../components/inspection/step_2';
import StepThree from '../components/inspection/step_3';
import StepFive from '../components/inspection/step_5';
import StepSeven from '../components/inspection/step_7';

import '../styles/inspection.css';

const TEXT_STEPS = [
  'Confirmacion',
  'Fotos Exterior',
  'Fotos Interior y Carnet'
];

const useStyles = makeStyles({
  root: {
    maxWidth: '80vw',
    flexGrow: 1,
    backgroundColor: 'transparent'
  },
  progress: {
    width: '100%',
  },
  barColorPrimary: {
    backgroundColor: 'green',
    color: 'green'
  },
  extraPadding: {
    padding: '0px 25px'
  }
});

const InspectionPage = ({
  date, 
  time,
  url_id,
  step
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(step - 2);
  const [open, setOpen] = React.useState(false);
  const [openCamera, setOpenCamera] = React.useState(false);
  const [editDataStatus, setEditDataStatus] = React.useState(false);
  const [modalPhotoContentSlides, setModalPhotoContentSlides] = React.useState(false);

  useEffect (()=>{
    if (step < 3) {
      updateTracking('Web App: Confirmación de datos ', url_id, 3);
      updateStep(url_id, 3);
    }
  },[]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditData = () => {
    if (!editDataStatus) {
      setOpen(true);
    }
  }

  const handleChangeData = () => {
    setOpen(false);
    setEditDataStatus(true);
  };

  const handleBack = () => {
    setModalPhotoContentSlides(true);
    setOpenCamera(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenCamera = () => {
    setOpenCamera(true);
  };

  const handleCloseCamera = () => {
    setOpenCamera(false);
  };

  const handleCloseCameraAndOpenModal = () => {
    setModalPhotoContentSlides(true);
  };
  const handleCloseModalAndOpenCamera = () => {
    console.log('handleCloseModalAndOpenCamera');
    setModalPhotoContentSlides(false);
    setOpenCamera(true);
  } 
  const handleCloseModalAndCloseCamera = () => {
    console.log('handleCloseModalAndOpenCamera');
    setModalPhotoContentSlides(false);
    setOpenCamera(false);
  }

  const renderStep = () => {
    if (activeStep === 1) {
      return (
        <StepOne 
          open={handleOpen} 
          close={handleClose} 
          editStatus={editDataStatus} 
          fnChangeDataStatus={handleChangeData}
          fnNextStep={setActiveStep}
        />
      )
    }

    if (activeStep === 3) {
      return (
        <StepThree 
          open={handleOpen} 
          close={handleClose}
          fnNextStep={setActiveStep}  
        />
      )
    }

    if(activeStep === 7) {
      return (
        <StepSeven open={handleOpen} 
        close={handleClose}
        fnNextStep={setActiveStep}   />
      )
    }

    return (
      <StepFive 
        open={handleOpenCamera} 
        close={handleCloseCamera}
        fnNextStep={setActiveStep}
      />
    );
  }

  return (
    <>
      <Modal
        open={openCamera}
        onClose={handleCloseCamera}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Camera 
          next={handleCloseCameraAndOpenModal}
          close={handleCloseModalAndCloseCamera} 
        /> 
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{
          width:'100vw',
          height:'100vh',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          border: 'none'
        }}>
          <div 
            style={{ 
              width:'80vw', 
              padding:'20px',
              backgroundColor:'#fff',
              borderRadius:'10px',
              display:'flex',
              flexDirection:'column',
              justifyContent:'center',
              alignItems:'center'

            }}
            >
            <img 
              style={{ transform: 'translateX(20px)'}}
              src={CarModal} width="60%" height="auto"
              loading="lazy" />
            <h6 style={{
              color:'#141B6A', 
              fontWeight:'normal',
              textAlign: 'center',
              letterSpacing: '0.15px',
              fontSize: '24px',
              margin: '0px'
              }}>Cambiar datos</h6>
            <p
              style={{
                lineHeight: '18px',
                textAlign: 'center',
                letterSpacing: '0.25px',
                color: '#666666'
              }}
            >Cualquier cambio que hagas sera enviado para revision de un inspector</p>
            <div style={{
              width:'100%',
              display:'flex',
              justifyContent:'space-around'
            }}>
              <button
                onClick={handleChangeData}
                style={{
                  color:'#6716F5', 
                  background: 'none',
                  border: 'unset',
                  letterSpacing: '1px',
                  fontWeight: '500',
                  lineHeight: '16px'
                }}
              >
                CAMBIAR DATOS
              </button>
              <button
                onClick={handleClose}
                
                style={{
                  color:'#6716F5', 
                  background: 'none',
                  border: 'unset',
                  letterSpacing: '1px',
                  fontWeight: '500',
                  lineHeight: '16px'
                }}
              >
                CANCELAR
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {
        activeStep !== 2 && 
        <>
          <Header
            text={[date, time]}
            model={2}
          />
          <Container
            classes={{root: classes.extraPadding}} 
            fixed>  
            <div className="inspection-container">
              {
                activeStep !== 4 &&
                <div className="stepper-container">
                  <MobileStepper
                    variant="progress"
                    steps={5}
                    position="static"
                    activeStep={activeStep > 4 ? 4 : activeStep}
                    classes={{root: classes.root, progress: classes.progress}}
                  />
                  <span className="steps-count">{`${activeStep > 4 ? 4 : activeStep}/4`}</span>
                </div>
              }
              {
                activeStep === 1   && 
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <>
                      <h3 style={{fontWeight: '400', color: '#141B6A', margin: '15px 0px'}}>Confirmacion de Datos</h3>
                      {/* <span
                        onClick={handleEditData}
                      >
                        {editDataStatus ? 
                          <img src={Pencil} loading="lazy" /> : 
                          <img src={PencilDisabled} style={{transform: 'translateX(-8px)'}} loading="lazy" />
                        }
                      </span> */}
                    </>
                  
                </div>
              }
              {
                renderStep()
              }
            </div>
          </Container>
        </>
      }
      {
        activeStep === 2 && 
        <StepTwo 
          open={handleOpenCamera} 
          close={handleCloseCamera} 
          fnNextStep={setActiveStep}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    url_id: state.url_id,
    date: state.date,
    time: state.time,
    step: state.step
  }
}

export default connect(
  mapStateToProps,
  null
)(InspectionPage);