import React from "react";
import CallEndIcon from "@material-ui/icons/CallEnd";
import Mic from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import { connect } from "react-redux";
import { setMuteAudio } from "../../../actions";
import FlipCameraIosIcon from "@material-ui/icons/FlipCameraIos";
import FlipCamera from "../../../assets/images/flip_camera.png";
import MicImage from "../../../assets/images/mic.svg";
import MicOff from "../../../assets/images/micOff.png";
import { IconButton } from "@material-ui/core";

const footerVideoConf = ({
  switchCameras,
  hangup,
  handleMute,
  isAudioActive,
  toggleCamera,
}) => {
  console.log("changes?");
  return (
    <footer
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      
      <span
        style={{
          height: 20,
          width: 180,
          backgroundColor: "red",
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          padding: "8px",
        }}
      >
        <span
          style={{
            color: "#ffffff",
            fontSize: "15px",
            fontWeight: 600,
          }}
          onClick={hangup}
        >
          Terminar llamada
        </span>
      </span>

      {/* </span> */}
      <IconButton onClick={toggleCamera}>
        <img src={FlipCamera} width="35" height="35" />
      </IconButton>

      {isAudioActive ? (
        <img src={MicImage} width={35} height={35} onClick={handleMute} />
      ) : (
        <img src={MicOff} width={35} height={35} onClick={handleMute} />
      )}
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(footerVideoConf);
