import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {
  useHistory
} from "react-router-dom";
import Button from '../../components/Button/ButtonFilled';
import MessageWarning from '../../components/Messages/warningMessage';
import Loading from '../../components/Loading';
import Header from './components/header';
import { saveInspection } from '../../actions';
import { getInspectionInfo } from '../../services';
import Conectivity from '../../assets/images/conexion.jpg'
const VideoConfIntro = ({ inspection, room, savingInspection }) => {
    const history = useHistory();
    const [loadingData, setLoadingData] = useState(true);

    let userOS;    // will either be iOS, Android or unknown
    let userOSver; // this is a string, use Number(userOSver) to convert

    function getOS() {
        var ua = navigator.userAgent;
        var uaindex;

        // determine OS
        if ( ua.match(/iPad/i) || ua.match(/iPhone/i) ) {
            userOS = 'iOS';
            uaindex = ua.indexOf( 'OS ' );
        } else if ( ua.match(/Android/i) ) {
            userOS = 'Android';
            uaindex = ua.indexOf( 'Android ' );
        } else {
            userOS = 'unknown';
        }

        // determine version
        if ( userOS === 'iOS'  &&  uaindex > -1 ) {
            userOSver = ua.substr( uaindex + 3, 3 ).replace( '_', '.' );
        }
        else if ( userOS === 'Android'  &&  uaindex > -1 ) {
            userOSver = ua.substr( uaindex + 8, 3 );
        } else {
            userOSver = 'unknown';
        }
        alert(userOS +' '+ userOSver);
    }

    useEffect(() => {
        console.log('inspectionBefore=>', inspection, loadingData)
        //getOS()
        const callGetInspectionAsync = async () => {
            const data = await getInspectionInfo(room);
            savingInspection(data)
            setLoadingData(false)
        }

        callGetInspectionAsync();
        console.log('inspectionAfter=>', inspection, loadingData)
        console.log('effecting...')
        sessionStorage.setItem('current__room', room);
    },[])
  

    console.log('loadingData=>', loadingData)
    return (
        <>  
            {
                !inspection  &&
                <Loading />
            } 
            {
                inspection  &&
                <>
                    <Header />
                    
                    <section
                        style={{
                            width:'80%',
                            margin:'0 auto'
                        }}
                    >
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <h2>Hola {`${inspection.name} ${inspection.lastname}`}</h2>
                        </div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <img src={Conectivity} />
                        </div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <p style={{textAlign:'center'}}>Asegurate de tener buena señal de internet al iniciar la video llamada</p>
                            <p style={{textAlign:'center'}}>Debes utilizar Chrome para esta videollamada.</p>
                        </div>
                        <Button 
                            text={'Continuar'}
                            clickAction={()=>history.push('/call/join')}
                        />
                    </section>
                </>
            }
            {
                !loadingData && !inspection &&
                <MessageWarning 
                    text={'Su link parece invalido. Por favor, contacte nuevamente con su agente.'}
                />
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      room: state.room,
      inspection: state.inspection
    }
}
const mapDispatchToProps = dispatch => {
    return {
        savingInspection: inspection => dispatch(saveInspection(inspection))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoConfIntro);