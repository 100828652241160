import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import VideoConfIntro from './intro';
import VideoConfJoin from './join';
import VideoConf from './videoconf';
import VideoConfSum from './summary';
import Videocall from "../videocall";

const RoutesVideoConference = () => {
    let { path, url } = useRouteMatch();
    let { room } = useParams();
    console.log('room=>', room)
    return (
        
        <Switch>
            <Route exact path={path}>
                <VideoConfIntro />
            </Route>
            <Route path={`${path}/join`}>
                <VideoConfJoin />
            </Route>
            <Route path={`${path}/room`}>
                {/* <VideoConf /> */}
                <Videocall />
            </Route>
            <Route path={`${path}/summary`}>
                <VideoConfSum />
            </Route>
        </Switch>
      
    );
}

export default RoutesVideoConference;