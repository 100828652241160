import React from "react";
import { Chip } from "@material-ui/core";

import mediumIcon from "../../assets/images/medium_icon.png";
import seriousIcon from "../../assets/images/serious_icon.png";
import mildIcon from "../../assets/images/mild_icon.png";


const SeverityComponent = (severity) => {
    switch (severity.severity) {
        case "minor":
          return (
            <Chip
              icon={<img src={mildIcon} alt="leve" width="25px" height="70%" />}
              label="LEVE"
              style={{ backgroundColor: "#E2F5EA" }}
            />
          );
        case "moderate":
          return (
            <Chip
              icon={<img src={mediumIcon} alt="medium" width="25px" height="70%" />}
              label="MEDIO"
              style={{ backgroundColor: "#FCEBDB" }}
            />
          );
        case "mid":
          return (
            <Chip
              icon={<img src={mediumIcon} alt="medium" width="25px" height="70%" />}
              label="MEDIO"
              style={{ backgroundColor: "#FCEBDB" }}
            />
          );
        case "severe":
          return (
            <Chip
              icon={<img src={seriousIcon} alt="serious" width="25px" height="70%" />}
              label="GRAVE"
              style={{ backgroundColor: "#EB5757" }}
            />
          );
        default:
          return "N/A";
      }
};

export default SeverityComponent;
