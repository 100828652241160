import { 
  ID, 
  SAVE_PHOTO, 
  SAVE_URL_ID,
  SAVE_URL_ROOM,
  SAVE_DATE,
  SAVE_STATUS,
  SAVE_STEP,
  SET_GUIDE,
  SAVE_INSPECTION,
  DELETE_PHOTO,
  SET_MUTE,
  SET_GRID_GUIDE,
  SET_PHOTO
} from '../actions';

const initialState = {
  url_id: null,
  room: null,
  id: -1,
  photos: [{},{},{},{},{},{},{},{},{},{},{},{},{}],
  photosByGuide: new Map(),
  date: null, 
  time: '00:00',
  step: 0,
  inspection: null,
  enabledAudio: true, 
  gridGuide: null,
  guide: {},
}

const AutoInspectionApp = (state = initialState, action) => {
  switch (action.type) {
    case ID:
      return {
        ...state, 
        id: action.payload
      }

    case SAVE_PHOTO:
      let newPhotos = []; 

      // usar filter luego
      for (let i = 0; i < state.photos.length; i++) {
        newPhotos[i] = {...state.photos[i]};
        if (i === action.payload.id){
          if ( !newPhotos[i].images ) {
            newPhotos[i].images = [];
          } 
          newPhotos[i].images.push({
            id: action.payload.image_id, 
            content: action.payload.photo
          });
          
        }
      }

      return {
        ...state, 
        photos: newPhotos
      }

    case SAVE_STATUS:
      let newObjPhotos = []; 

      // usar filter luego
      for (let i = 0; i < state.photos.length; i++) {
        newObjPhotos[i] = {...state.photos[i]};
        if (i === action.payload.id) {

          if ( !newObjPhotos[i].status ) {
            newObjPhotos[i].status = '';
          } 
          newObjPhotos[i].status = action.payload.status;
        }
      }
      return {
        ...state, 
        photos: newObjPhotos
      }

    case SAVE_URL_ID:
      return {
        ...state,
        url_id: action.payload
      }

    case SAVE_URL_ROOM:
      return {
        ...state,
        room: action.payload
      }  
    
    case SAVE_DATE:
      return {
        ...state,
        date: action.payload[0],
        time: action.payload[1]
      }
    
    case SAVE_STEP:
      return {
        ...state,
        step: action.payload
      }
    
    case SAVE_INSPECTION: 
      return {
        ...state,
        inspection: action.payload
      }

    case DELETE_PHOTO:
      let _newObjPhotos = [];
      for (let i = 0; i < state.photos.length; i++) {
        _newObjPhotos[i] = {...state.photos[i]};
        if (i === action.payload.id) {
          const objWiEliminatedImage = _newObjPhotos[i].images.filter (
            image => image.id !== action.payload.image_id
          );
            
          _newObjPhotos[i].images = objWiEliminatedImage;
        }
      }
      return {
        ...state, 
        photos: _newObjPhotos
      } 
    
    case SET_MUTE: 
      return {
        ...state,
        enabledAudio: !state.enabledAudio
      }

    case SET_GRID_GUIDE:
      return {
        ...state,
        gridGuide: action.payload
      }

    case SET_PHOTO:
      const newMap = new Map(state.photosByGuide);
      const arrGuidePhotos = newMap.get(action.payload.id);
      const photoInfo = arrGuidePhotos ? 
                    (arrGuidePhotos.find( ({ name }) => name === action.payload.name) ?? { name: '', photo: null, position: -1 }) : 
                    { name: '', photo: null, position: -1 };
      photoInfo.name = action.payload.name;
      photoInfo.photo = action.payload.photo;
      photoInfo.position = action.payload.position;
      let copyMapEntry = arrGuidePhotos ? arrGuidePhotos.filter(({ name }) => name !== action.payload.name) : []
      copyMapEntry = [...copyMapEntry, photoInfo];
      newMap.set(action.payload.id, copyMapEntry);
      return {
        ...state,
        photosByGuide: newMap
      }

    case SET_GUIDE: 
      console.log('guideinfo====>', action.payload);
      return {
        ...state,
        guide: action.payload
      }  
    default:
      return state;
  }
}

export default AutoInspectionApp;