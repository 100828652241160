import React, {useState, useEffect} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import {connect} from 'react-redux';
import { Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { config } from '../../config';
import { setGridGuide } from '../../actions'
import { getBrands, getModels, getVersionsByModel } from '../../services/carServices';
import '../../styles/inspection.css';

const useStyles = makeStyles({
  root: {
    padding: '0px'
  }
});

const useStylesInput = makeStyles({
  root: {
    width: '100%'
  }
});

const StepComponent = ({ 
  url_id, 
  editStatus = false,
  fnNextStep,
  savingGridGuide,
  fnChangeDataStatus= ()=>{}
}) => {
  const { register, handleSubmit, clearErrors, errors, setValue } = useForm();
  const [loaded, setLoaded] = useState(false);
  const [name, setName] = useState('');
  const [version, setVersion] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [year, setYear] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [yearsData, setYearsData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [modelsData, setModelsData] = useState([]);
  const [versionsData, setVersionsData] = useState([]);
  
  const classes = useStyles();
  const classesInput = useStylesInput();

  useEffect(() => {
    const fetchData = async () => {
      // brands data

      await axios.get(`${config.CAR_INFORMATION_URL}/catalog/years`)
      .then( ({ data })  => {
        const arrayData = data.map(
          year => {
            return {'label': year, 'value': year};
          }
        );
        
        setYearsData(arrayData);
      });
      await axios.get(`${config.CAR_INFORMATION_URL}/catalog/brand/`)
        .then( ({ data })  => {
          setBrandsData(data);
        });
    };
 
    fetchData();

  }, []);

  useEffect(() => {
    const resquestFunc = async () => {
      
      if( url_id && url_id!=='') {

        await axios.get(`${config.SERVER_URL}/inspections/temporary/${url_id}`)
          .then( async ({ data }) => {
            if (data.id) {

              const brands = await getBrands(); 
              const models = await getModels(data.brandId);
              const versions = await getVersionsByModel(data.modelId);

              const brand = brands.find( selfbrand => {
                return selfbrand._id === data.brandId
              });
              const model = models.find( selfmodel => {
                return selfmodel._id === data.modelId
              });
              // const version = versions.find( selfversion => {
              //   return selfversion._id === data.versionId
              // });
              // debugger
              setYear(data.year.toString());
              setBrand(brand.name || data.brandId);
              setModel(model.name || data.modelId);
              setValue('name', data.name);
              // setVersion(version.name || data.versionId || '');
              setName(data.name+' '+data.lastname);
              savingGridGuide(data.guideId);
            }

          });
          setLoaded(true);
      } else {
        setLoaded(true);
      }
    }

    resquestFunc();
  }, [url_id]);

  const handleClose = () => {
    setOpenModal(false);
  }

  const onChangeYear = async (ev, val) => {
    console.log('errors', errors);
    if (val?.value) {
      setYear(val.value);
      clearErrors('year');
      await axios.get(`${config.CAR_INFORMATION_URL}/catalog/brand/`)
        .then( ({ data })  => {
          setBrandsData(data);
        });
    }
  }

  const onChangeBrand = (ev, val) => {

    if (val?._id) {
      clearErrors('brand');
      setBrand(val.name);
      axios.get(`${config.CAR_INFORMATION_URL}/catalog/model/${val._id}/`)
        .then( ({ data }) => {
          setModelsData(data);
        });
    }

  }

  const onChangeModel = (ev, val) => {
    if (val?._id) {
      clearErrors('model');
      setModel(val.name);
      axios.get(`${config.CAR_INFORMATION_URL}/catalog/version/${val._id}`) 
        .then( ({ data }) => {
          setVersionsData(data);
        });
    }
  }

  const onChangeVersion = (ev, val) => {
    if (val?._id) {
      clearErrors('version');
      setVersion(val.name);
    }
  }

  const onSubmit = data => {

    const inputsIdValues = {
      brand,
      model,
      version,
      year
    };

    axios.put(`${config.SERVER_URL}/inspections/temporary/${url_id}`, {...inputsIdValues, id: url_id})
      .then( ( res ) => {
        fnNextStep(2);
      })
      .finally(
        () => {
          fnChangeDataStatus(false);
        });
  }

  const handleConfirmButton = () => {
    fnNextStep(2);
  }

  return (
    <>
      <Container
        classes={{
          root: classes.root
        }}
      >
        <form 
          onSubmit={handleSubmit(onSubmit)}
        >
          {
            loaded ?
            <>              
              <div className="input-group"> 
                <div className="container-label">
                  <label>Año</label>
                  {
                    errors?.year && <ErrorIcon color='error' style={{ width: '18px', marginLeft:'10px' }} />
                  }
                </div>
                <Autocomplete
                  options={yearsData}
                  getOptionLabel={(option) => option.label}
                  defaultValue={{ label: year}}
                  disabled={!editStatus} 
                  onChange={onChangeYear}
                  renderInput={
                    (params) => 
                    <TextField 
                      {...params}  
                      inputRef={register({ required: true })} 
                      error={errors?.year || false}
                      name="year"
                      variant="outlined" 
                      value={year}
                    />
                  }
                />
                <div style={{marginBottom:'10px'}}>{errors?.year && <small className="error-message">Campo obligatorio</small> }</div>
              </div> 
              <div className="input-group" style={{marginTop:'15px'}}>
                <div className="container-label">
                  <label>Marca</label>
                  {
                    errors?.brand && <ErrorIcon color='error' style={{ width: '18px', marginLeft:'10px' }}/>
                  }
                </div>
                <Autocomplete
                  options={brandsData}
                  getOptionLabel={(option) => option.name}
                  disabled={!editStatus} 
                  onChange={onChangeBrand}
                  defaultValue={{ name: brand}}
                  renderInput={
                    (params) => 
                    <TextField 
                      {...params}   
                      inputRef={register({ required: true })} 
                      error={errors?.brand || false}
                      name="brand"
                      variant="outlined" 
                      value={brand}
                    />
                  }
                  />
                <div style={{marginBottom:'10px'}}>{errors?.brand && <small className="error-message">Campo obligatorio</small> }</div>
              </div>
              <div className="input-group" style={{marginTop:'15px'}}>
                <div className="container-label">
                  <label>Modelo</label>
                  {
                    errors?.model && <ErrorIcon color='error' style={{ width: '18px', marginLeft:'10px' }}/>
                  }
                </div>
                <Autocomplete
                  options={modelsData}
                  getOptionLabel={(option) => option.name}
                  disabled={!editStatus} 
                  onChange={onChangeModel}
                  defaultValue={{ name: model}}
                  renderInput={
                    (params) => 
                    <TextField 
                      {...params}  
                      inputRef={register({ required: true })} 
                      error={errors?.model || false}
                      name="model"
                      variant="outlined" 
                      value={model} 
                    />
                  }
                  />
                <div style={{marginBottom:'10px'}}>{errors?.model && <small className="error-message">Campo obligatorio</small> }</div>
              </div>
              <div className="input-group" style={{marginTop:'15px'}}> 
                <div className="container-label">
                  <label>Version</label>
                  {
                    errors?.version && <ErrorIcon color='error' style={{ width: '18px', marginLeft:'10px' }}/>
                  }
                </div>
                <Autocomplete
                  options={versionsData}
                  getOptionLabel={(option) => option.name}
                  disabled={!editStatus} 
                  onChange={onChangeVersion}
                  defaultValue={{ name: version}}
                  renderInput={
                    (params) => 
                    <TextField 
                      {...params}  
                      inputRef={register({ required: true })} 
                      error={errors?.version || false}  
                      name="version"
                      variant="outlined" 
                      value={version} 
                    />
                  }
                />
                <div style={{marginBottom:'10px'}}>{errors?.version && <small className="error-message">Campo obligatorio</small> }</div>
              </div>
              <div className="input-group" style={{marginTop:'15px'}}>
                <div className="container-label">
                  <label>Asegurado</label>
                  
                </div>
                <TextField 
                  value={name}
                  disabled
                  id="name"  
                  inputRef={register}
                  name="name"
                  classes={{root:classesInput.root, label: classesInput.label}}
                  error={errors?.name || false}
                />
              </div> 
            </>
            :
            <>Loading...</>    
          }  
          <div className="container-button"> 
            {
              editStatus ?
              <input 
                type="submit" 
                className={'btn-submit btn-purple ' + (Object.keys(errors).length > 0 ? 'disabled' : '')}
                value="GUARDAR"
                disabled={Object.keys(errors).length > 0}
              />
              :
              <button 
                className={'btn-submit btn-purple'}
                onClick={handleConfirmButton}
              >CONFIRMAR</button> 
            } 
          </div>
        </form>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    url_id: state.url_id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    savingGridGuide: guide => {
      dispatch(setGridGuide(guide))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepComponent);