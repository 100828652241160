import React, { useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import piexif from 'piexifjs';
import { connect } from 'react-redux';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import axios from 'axios';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { savePhoto } from '../../actions';
import { config } from '../../config';
import LandscapeIcon from '../../assets/images/landscape_vector.png';
import GridIcon from '../../assets/images/grid_vector.png';
import LightIcon from '../../assets/images/light_vector.png';
import FlashIcon from '../../assets/images/flash_vector.png';
import { bytesArrayOpt, composeFormDataImgObj, composeExifData } from '../../utils';
import '../../styles/camera.css';

const MAP_TYPE_PHOTO = [
  'side_frontal_left', 
  'frontal', 
  'side_frontal_right', 
  'side_left_top',
  'side_right_top', 
  'side_left_lower', 
  'side_right_lower', 
  'side_back_left',
  'back', 
  'side_back_right',
  'dashboard', 
  'front_card', 
  'back_card'
];

const MAP_TYPE_PHOTO_HEADER = [
  'Frontal lateral izquierdo', 
  'Frontal', 
  'Frontal lateral derecho', 
  'Lateral izquierda superior',
  'Lateral derecha superior',
  'Lateral izquierda inferior', 
  'Lateral derecha inferior',
  'Trasera lateral izquierda',
  'Trasera', 
  'Trasera lateral derecha',
  'Tablero', 
  'Frente carnet', 
  'Reverso carnet'
];

const CameraComponent = ({ id, close, next, savingPhotoAtStore, url_id }) => {

  const handleTakePhoto = async (dataUri) => {
    try {
      const [exifBytes, exifObj] = await composeExifData();
      // base64 file 
      let exifModified = piexif.insert(exifBytes, dataUri);
      const image_id = uuidv1();
      savingPhotoAtStore(id, dataUri, image_id);
      const byteArrays = bytesArrayOpt(exifModified);
      const formDataToUpload = composeFormDataImgObj('image/jpeg', byteArrays, MAP_TYPE_PHOTO[id], url_id, image_id, JSON.stringify(exifObj));
  
      let configRequest = {
        header : {
          'Content-Type' : 'multipart/form-data'
        }
      }
      await 
        axios
          .post(`${config.SERVER_URL}/inspections/upload`, formDataToUpload, configRequest)
          .catch( err => {
            console.log('err=>', err)
          })  
          .finally(()=>
            close()
          );
      close();
    } catch (e) {
      console.log('err=>', e);
    }

  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      width: '100vw'
    }}>
      <div
        style={{
          display:'flex', 
          alignItems:'center',
          justifyContent: 'space-start',
          backgroundColor: '#fff',
          width:'100vw',
          height:'10vh'
        }}
      > 
        <span
          onClick={()=>close()}
          style={{marginLeft:'10px'}}
        >
          <HighlightOffIcon style={{color: '#6716F5'}}/>
        </span>
        <span style={{marginLeft:'10px'}}>{ MAP_TYPE_PHOTO_HEADER[id] }</span>
      </div>
        <>
          <div>
              <Camera
                onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                idealFacingMode = {FACING_MODES.ENVIRONMENT}
                imageType = {IMAGE_TYPES.JPG}
                isImageMirror={false}
                idealResolution={{
                  width : Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0), 
                  height : Math.max(document.documentElement.clientHeight * 0.8 || 0, window.innerHeight* 0.8 || 0)
                }}
              />
          </div>
          <div
          style={{
            display:'flex', 
            justifyContent: 'space-evenly',
            alignItems:'center',
            backgroundColor: '#fff',
            width:'100vw',
            height:'10vh'
          }}
        >
          <img src={LightIcon} height="50%" width="auto" />
          <img src={FlashIcon} height="50%" width="auto" />
          <img 
            onClick={()=>window.screen.orientation.lock('landscape')}
            src={LandscapeIcon} height="50%" width="auto" 
          />
          <img src={GridIcon} height="50%" width="auto" />
        </div>
        </>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    id: state.id,
    url_id: state.url_id
  }
}
const mapDispatchToProps = dispatch => {
  return {
    savingPhotoAtStore: (id, data, image_id) => dispatch(savePhoto(id, data, image_id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraComponent);
