import React from 'react';
import './index.css';

const Button = ({
  text,
  clickAction = undefined,
  active
}) => {
  return (
    <button 
      className={active ? "button-disabled" : "border-button-container"}
      onClick={clickAction}
      disabled={active}
    >
      {text}
    </button>
  )
}

export default Button;