import React from 'react';
import CustomGridGuide from '../gridGuides/customGrid';
import CarGridGuide from '../gridGuides/carGridGuide'

const GuideSelector = ({ data, guide }) => {

    // const GUIDE_SELECTED = [
    //     // 'auto': <CarGridGuide />, // keep same grid
    //     'otro': <CustomGridGuide />
    // ];

    // console.log('guide=>', guide, GUIDE_SELECTED)

    return (
        <>
            <CustomGridGuide guideInfo={data} />
        </>
    );
}

export default GuideSelector;