import React from 'react';
import {
  connect
} from 'react-redux';
import Header from '../components/header';
import StepTwo from '../components/steps/step_2';
import '../styles/introduction.css';

const IntroductionPage = ({
  date, 
  time
}) => {
  return (
    <div>
      <div className="introduction-container">
        <Header 
          text={[date, time]}
          model={2}
        />
        <StepTwo />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    date: state.date,
    time: state.time
  }
}

export default connect(
  mapStateToProps,
  null
)(IntroductionPage);