export const literals = {
  "introductionSecondStepText": "Antes de empezar a sacar tus fotos, te dejamos algunas sugerencias",
  "introductionFirstStepText": "Solo te llevará 15 minútos realizar la autoinspección",
  "introductionFirstStepTextExpired": "El link de la inspección se encuentra vencido, por favor comuníquese con el inspector, para que le genere un nuevo link",
  "introductionFirstStepSubText": (name ) => `iHola, ${name}!`,
  "introductionNaturalLight": "Saca las fotos con buena luz y espacio suficiente. Las fotos de exterior deben mostrar el vehículo por completo",
  "introductionCleanliness": "Tu vehículo debe estar limpio y con la ventanas cerradas",
  "introductionConnectivity": "Asegúrate de tener buena señal de internet al iniciar la inspección",
}

export const MAP_TYPE_PHOTO = [
  'frontal', 'trasera', 'lat_derecha', 'lat_izquierda', 'tablero', 
  'frente_carnet', 'reverso_carnet'
];

export const stepsAutoInspection = {
  
  inspectionRejected: -1,
  inspectionCancelled: -2,
  inspectionNotFound: -3,
  inspectionLinkInvalid: -4,
  inspectionNetworkError: -5,
  start: 0,
}