import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import Button from '../../components/Button/ButtonFilled';
import Success from '../../assets/images/success.jpg';
import Header from './components/header';

const VideoConfSummary = () => {
    let match = useRouteMatch();
    
    useEffect(() => {
      window.addEventListener('popstate', function(event) {
          window.history.go(-3)
      });

      sessionStorage.removeItem('current__room');
    },[])

  
    return (
        <>
          <Header />
          <section
            style={{
                width:'80%',
                margin:'0 auto'
            }}
          >
            <div style={{display:'flex', justifyContent:'center'}}>
                <h2 style={{textAlign:'center'}}>La video llamada ha finalizado</h2>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
                <p style={{textAlign:'center'}}>Te enviaremos un email cuando confirmemos la inspeccion</p>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
                <p>Gracias por elegirnos!!</p>
            </div>
            <div
              style={{
                display:'flex',
                justifyContent:'center'
              }}
            >
              <img src={Success} />
            </div>
          </section>
        </>
      
    );
}

export default VideoConfSummary;