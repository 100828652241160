import React from 'react';
import './index.css';

const Button = ({
  text,
  clickAction = undefined
}) => {
  return (
    <button 
      className="border-button-container"
      onClick={clickAction}
    >
      {text}
    </button>
  )
}

export default Button;