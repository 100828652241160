import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { updateTracking, updateStep } from '../../services';
import Grid from '@material-ui/core/Grid';
import ButtonBorder from '../Button/ButtonBorder';
import PhotoCard from '../photoCard';
import ErrorIcon from '@material-ui/icons/Error';
import CarPhoto from '../../assets/images/car_photo.jpeg';

import NavModal from '../NavModal';

const StepComponent = ({
  photos, 
  save, 
  open, 
  close, 
  fnNextStep, 
  url_id,
  step
}) => {

  useEffect (()=>{
    if (step < 4) {
      updateTracking('Web App: Fotos exteriores ', url_id, 4);
      updateStep(url_id, 4);
    }
  },[]);

  return (
    <NavModal fnNextStep={fnNextStep} />
  );
}

const mapStateToProps = (state) => {
  return { 
    photos: state,
    url_id: state.url_id,
    step: state.step
  }
};

export default connect(mapStateToProps)(StepComponent);