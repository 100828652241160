const WORD_AI_PART_TYPE = {
  scratch: "rasguño",
  dent: "abolladura",
  license: "licencia",
  fender_rear: "guardabarros trasero",
  tail_lamp: "luz trasera",
  door: "puerta",
  wheel: "rueda",
  bumper: "parachoques",
  trunk: "maletero",
  mirror: "espejo",
  top: "techo",
  head_lamp: "faro delantero",
  head: "faro delantero",
  hood: "capó",
  grill: "parrilla",
  emblem: "emblema",
  window: "ventana",
  lock: "cerradura",
  antenna: "antena",
  exhaust: "escape",
  wiper: "limpiaparabrisas",
  windshield: "parabrisas",
  mouldings: "molduras",
  fender_front: "guardabarros delantero",
  frame: "marco",
  sticker: "pegatina",
  hook: "gancho",
  gas: "gasolina",
  spoiler: "alerón",
  handle: "manija",
  fog_lamp: "luz antiniebla",
  spray: "pulverización",
  turn_signal: "intermitente",
  side_step: "estribo",
  roof_rack: "portaequipajes",
  bumper_guard: "protector de parachoques",
  front_left: "Frontal lateral izquierdo",
  front_front: "Frontal",
  front_right: "Frontal lateral derecho",
  side_left: "Lateral izquierda superior",
  side_right: "Lateral derecha superior",
  rear_left: "Trasera lateral izquierda",
  rear_rear: "Trasera",
  rear_right: "Trasera lateral izquierda"
};

export const getWordSpanish = (word) => {
  for (const key in WORD_AI_PART_TYPE) {
    if (key === word) return WORD_AI_PART_TYPE[key];
  }
};
