import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import { updateStep, updateTracking } from '../services';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import Header from '../components/header';
import Portrait from '../components/portrait';
import Success from '../assets/images/success.jpg';
import MiniSuccess from '../assets/images/mini_success.png';
import '../styles/success.css';

const TEXT = 'Te enviaremos un email cuando confirmemos su recepción';
const useStyles = makeStyles({
  root: {
    maxWidth: '70vw',
    flexGrow: 1,
    backgroundColor: 'transparent'
  },
  progress: {
    width: '100%'
  }
});

const SuccessPage = ({ url_id }) => {
  useEffect(()=>{
    updateTracking('Web App: Fin de inspección', url_id, 7);
    updateStep(url_id, 7);
  },[]);

  const classes = useStyles();
  return (
    <>
      <Header />
      <div className="container-success">
        <div style={{display:'flex'}}>
          <MobileStepper
            variant="progress"
            steps={5}
            position="static"
            activeStep={4}
            className={[classes.root, classes.progress]}
          />
          <img src={MiniSuccess} style={{height:'20px', width:'20px', marginLeft:'10px'}} />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={Success} width="80%" height="auto"/>
        </div>
        <h2 className="text-center">La inspeccion ha sido enviada</h2>
        <p className="text-center">{TEXT}</p>
        <p className="text-center">Gracias por elegirnos!!</p>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    url_id: state.url_id
  }
}

export default connect(
  mapStateToProps,
  null
)(SuccessPage);