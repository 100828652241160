import piexif from 'piexifjs';

export const bytesArrayOpt = bytesArr => {
  
  let base64data = bytesArr.split('data:image/jpeg;base64,');
  const byteCharacters = atob(base64data[1]);
  const sliceSize=512;

  const optimizedBytesArray = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    optimizedBytesArray.push(byteArray);
  }

  return optimizedBytesArray;
}

export const composeFormDataImgObj = (blobType, byteArrays, type, id, image_id, meta) => {
  
  const blob = new Blob(byteArrays, {type: blobType});
  let formDataobj = new FormData();
  formDataobj.append('file', blob);
  formDataobj.append('type', type);
  formDataobj.append('id', id);
  formDataobj.append('image_id', image_id);
  formDataobj.append('meta', meta);

  return formDataobj;
}

export const composeFormDataGuideImg = (blobType, byteArrays, id, name, sectionId, position) => {
  
  const blob = new Blob(byteArrays, {type: blobType});
  let formDataobj = new FormData();
  formDataobj.append('file', blob);
  formDataobj.append('name', name);
  formDataobj.append('id', id);
  formDataobj.append('sectionId', sectionId);
  formDataobj.append('position', position);

  return formDataobj;
}

export const getCoords = async () => {
  const pos = await new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {

      navigator.geolocation.getCurrentPosition(resolve, reject);
      console.log('pos2=>');

    } else { 
      console.log("Not Available");
    }
    
  });

  return {
    long: pos.coords.longitude,
    lat: pos.coords.latitude,
  };
}

export const composeExifData = async () => {
  const date = new Date();
  // make exif data
  let zerothIfd = {};
  let exifIfd = {};
  let gpsIfd = {};
  // zerothIfd[piexif.ImageIFD.Make] = "Test Name";
  // zerothIfd[piexif.ImageIFD.XResolution] = [777, 1];
  // zerothIfd[piexif.ImageIFD.YResolution] = [777, 1];
  // zerothIfd[piexif.ImageIFD.Software] = "Sistran - autoinspection";
  // exifIfd[piexif.ExifIFD.LensMake] = "Lens Maker - test";
  // exifIfd[piexif.ExifIFD.Sharpness] = 777;
  // exifIfd[piexif.ExifIFD.LensSpecification] = [[1, 1], [1, 1], [1, 1], [1, 1]];
  // gpsIfd[piexif.GPSIFD.GPSVersionID] = [7, 7, 7, 7];
  // gpsIfd[piexif.GPSIFD.GPSDateStamp] = "1999:99:99 99:99:99";
  let exifObj = {};
  let exifBytes = null;

  const position = await getCoords();
  gpsIfd[piexif.GPSIFD.GPSVersionID] = [7, 7, 7, 7];
  gpsIfd[piexif.GPSIFD.GPSLatitudeRef] = position.lat < 0 ? 'S' : 'N';
  gpsIfd[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(position.lat);
  gpsIfd[piexif.GPSIFD.GPSLongitudeRef] = position.long < 0 ? 'W' : 'E';
  gpsIfd[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(position.long);

  const month = (date.getMonth()+1).toString().length < 2  ? '0'+(date.getMonth()+1).toString() : (date.getMonth()+1).toString();
  const day = date.getDate().toString().length < 2 ? '0'+date.getDate().toString() : date.getDate().toString();
  const hours = date.getHours().toString().length < 2 ? '0'+date.getHours().toString() : date.getHours().toString();
  const minutes = date.getMinutes().toString().length < 2 ? '0'+date.getMinutes().toString() : date.getMinutes().toString();

  exifIfd[piexif.ExifIFD.DateTimeOriginal] = date.getFullYear().toString()+':'+month+':'+day+' '+hours+':'+minutes+':10';
  gpsIfd[piexif.GPSIFD.GPSDateStamp] = date.getFullYear().toString()+':'+month+':'+day+' '+hours+':'+minutes+':10';

  exifObj = {"0th":zerothIfd, "Exif":exifIfd, "GPS":gpsIfd};
  try {
    exifBytes = piexif.dump(exifObj);
  } catch (e) {
    console.log('err=>', e);
    let lat = 59.43553989213321;
    let lng = 24.73842144012451;
    gpsIfd[piexif.GPSIFD.GPSLatitudeRef] = lat < 0 ? 'S' : 'N';
    gpsIfd[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(lat);
    gpsIfd[piexif.GPSIFD.GPSLongitudeRef] = lng < 0 ? 'W' : 'E';
    gpsIfd[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(lng);
    exifBytes = piexif.dump(exifObj);
    gpsIfd[piexif.GPSIFD.GPSLatitudeRef] = position.lat < 0 ? 'S' : 'N';
    gpsIfd[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(position.lat);
    gpsIfd[piexif.GPSIFD.GPSLongitudeRef] = position.long < 0 ? 'W' : 'E';
    gpsIfd[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(position.long);
  }
  
  return [exifBytes, exifObj];
}

export const validate =  {
    isString: ( value ) => typeof value === 'string',
    isInt: ( value ) => Number.isInteger(value) && value % 1 === 0
};

export const fnTryCatch =  async ( tryer ) => {
  try {
    const result = await tryer();
    return [result, null]
  } catch (error) {
    return [null, error]
  }
};