import React, { useState, useEffect } from 'react';
import {
  useHistory
} from "react-router-dom";
import { connect } from 'react-redux';
import { saveGuide, saveStep } from '../actions';
import { getGuideInfo, getTracking } from '../services';
import LoadingImage from '../assets/images/logo.webp';

const Loading = ({ id, savingGuideInfo, savingStep }) => {
  let history = useHistory();
  const [step, setStep] = useState(null);

  useEffect(() => {
    const gettingStep = async (temporaryId) => {
      const step = await getTracking(temporaryId);
      const response = await getGuideInfo(temporaryId);
      if ( response ) {
        const { data: guideInfoResponse } = response;
        savingGuideInfo(guideInfoResponse);
        setStep(step);
      } else {
        setStep(-1);
      }
    };

    gettingStep(id);
  },[]);

  if ( typeof step === 'number' ) {

    switch ( parseInt(step) ) {
      case -1:
        alert('Esta inspeccion ha sido rechazada. Por favor comuniquese con su asegurador.');
        return false;
      case -2:
        alert('Esta inspeccion ha sido cancelada. Por favor comuniquese con su asegurador.');
        return false;
      case -3:
        alert('Esta inspeccion no pudo ser encontrada. Por favor comuniquese con su asegurador.');
        return false;
      case -4:
        alert('El link de la autoinspeccion es invalido. Por favor comuniquese con su asegurador');
        return false;
      case -5:
        alert('No pudimos conectar con el servidor. Por favor Intente nuevamente luego.');
        return false;
      default:
        savingStep(step);
        
        if ( step === 0 || step === 1 || step === 2) {
          history.replace("/1")
        }
        if ( 3 <= step && step <= 6 ) {
          history.push("/autoinspection");
        }
        if ( step >= 7 ) { 
          history.push("/success");
        }
        break;
    }

  }


  return (
    <div
      style={{
        display:'flex',
        height:'100vh',
        width:'100%',
        justifyContent:'center',
        alignItems:'center',
        background: '#6716f517'
      }}
    >
      <img 
        width="80%"
        src={LoadingImage} />
    </div>
  )
}


const mapStateToProps = null;
const mapDispatchToProps = dispatch => {
  return {
    savingStep: (id) => dispatch(saveStep(id)),
    savingGuideInfo: (guideInfo) => dispatch(saveGuide(guideInfo)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loading);