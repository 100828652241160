import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { Container } from '@material-ui/core';
import Portrait from '../portrait';
import Button from '../Button/ButtonBorder';
import { literals } from '../../utils/constants';
import CarImage from '../../assets/images/car_introduction.jpg';
import { getName, getInspectionInfo } from '../../services';
import '../../styles/step.css';

const StepComponent = ({
  id
}) => {
  const [expired, setExpired] = useState(false)
  const [name, setName] = useState('');
  const [text, setText] = useState(literals.introductionFirstStepText)

  let history = useHistory();
  
  const handleClick = () => {
    history.push('/2');
  }

  useEffect (()=>{
    const gettingName = async ()=> {
      const name = await getName(id);
      setName(name);
    };
    const gettingInspectionInfo = async ()=> {
      const inspection = await getInspectionInfo(id);
      if (inspection.status == "Vencida") {
        setText(literals.introductionFirstStepTextExpired)
        setExpired(true)
      }
    };
    gettingName();
    gettingInspectionInfo()
  },[]);

  return (
    <>
      <Container fixed>
        <Portrait 
          image={CarImage}
          text={text} 
          subtext={literals.introductionFirstStepSubText(name)} 
        />
      </Container>
      <div className="button-container">
        <Button 
          text="EMPEZAR AUTOINSPECCION"
          clickAction={handleClick}
          active={expired}
        />
      </div>
    </>
  );
}

export default StepComponent;