import React from 'react';
import LoadingImage from '../../assets/images/logo.webp';

const LoadingComponente = () => {
  return (
    <div
      style={{
        display:'flex',
        height:'100vh',
        width:'100%',
        justifyContent:'center',
        alignItems:'center',
        background: '#6716f517'
      }}
    >
      <img 
        width="80%"
        src={LoadingImage} />
    </div>
  )
}
export default LoadingComponente;