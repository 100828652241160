import React from 'react';
import '../../styles/portrait.css';

const portraitComponent = ({ 
  image="https://via.placeholder.com/250x150", 
  text,
  subtext="", 
}) => {
  
  return (
    <div className={"portrait-container"}>
      <img 
        loading="lazy"
        src={image} 
        className={"img-portrait-style " + (subtext !== "" ? 'img-portrait-style-refinement' : '' ) }
      />
      {
        subtext !== "" && 
        <p className="text-center tit-color-1">{subtext}</p>
      }
      <p 
        className={"text-center text-content-styles"}>{text}</p>
    </div>
  );
}

export default portraitComponent;