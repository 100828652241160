import React from 'react';

const WarningMessage = ({
  text, 
  messageStyles=null,
  close=null
}) => {
  return (
    <div 
      style={{
          position: 'absolute',
          border: '1px solid red',
          padding: '10px',
          bottom: 0,
          backgroundColor: '#fff'  
      }}
    >
      <p>{text}</p>
      {
        close &&
        <div>
          <button onClick={close}>Cerrar</button>
        </div>
      }
    </div>
  )
}

export default WarningMessage;