import React, { useEffect } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { connect } from 'react-redux';
import ImagePlaceholderEmpty from '../../assets/images/image_placeholder.png';
import ImagePlaceholder from '../../assets/images/new_image_icon.png';
import PhotoStatus from '../../assets/images/photos_status.png';
import DeleteImage from '../../assets/images/close.png';
import { deletePhoto   } from '../../actions';
import '../../styles/photo_slide.css';

const CameraComponent = ({ 
  id = 0, 
  deletingImage,
  photos, 
  url_id, 
  close, 
  back, 
  next 
}) => {

  const handleDeletingImage = (id, image_id) => {
    deletingImage(id, image_id)
  }

  if (( id >= 0 && photos[id] ))
    return (
      <div className="photoslide-container">
        <div
        style={{
          display:'flex', 
          alignItems:'center',
          justifyContent: 'space-start',
          backgroundColor: '#fff',
          height:'10vh',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)'
        }}
      > 
        <span
          onClick={()=>next(1)}
          style={{marginLeft:'10px'}}
        >
          <HighlightOffIcon style={{color: '#6716F5'}}/>
        </span>
        <span style={{marginLeft:'10px'}}>Detalle</span>
      </div>
        {
          <>
            <div className="main-photo-container">
              <img 
                id={"current-photo"}
                src={
                  ( (photos[id].images && photos[id].images.length > 0 ) && photos[id].images[photos[id].images.length - 1].content) 
                  || ImagePlaceholderEmpty
                }
                width={photos[id].images && '60%'}
              />
            </div>
            <div className="carrousel-photos">
              <div className="add-photo-container">
                <img 
                  onClick={()=>next(1)}
                  src={ImagePlaceholder} 
                />
              </div>
              {
                photos[id].images.map(
                  photoInfo => {
                    return (
                      <div style={{position:'relative'}}>
                        <img src={photoInfo.content} className="photo-carrousel" />
                        <img 
                          src={DeleteImage} 
                          style={{position:'absolute', top:0, right:0}} 
                          height="20px" 
                          width="20px"
                          onClick={ () => handleDeletingImage(id, photoInfo.id) } 
                        />
                      </div>
                    )
                  }
                )
              }
              <div className="status-container">
                <img 
                  src={PhotoStatus} 
                  onClick={()=>{
                    // get_metadata();
                    next(3);
                  }}
                />
              </div>
            </div>
          </>
        }
      </div>
    );
  
  return (
    <>
      <h1>Photos Slide</h1>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    id: state.id,
    url_id: state.url_id,
    photos: state.photos
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deletingImage: (id, image_id) => dispatch(deletePhoto(id, image_id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CameraComponent);