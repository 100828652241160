import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconnectanLogo from '../../assets/images/iconnectance_logo.png';
import '../../styles/header.css';

const HeaderComponent = ({ 
  text = '', 
  model=1,
}) => (
  <header className="header-container">
    <Grid container>
      <Grid item xs={2} sm={10}>
        <div className="center">
          <img src={IconnectanLogo} width="36" height="36" />
        </div>
      </Grid>

      {
        model === 1 &&
        <Grid item xs={10} sm={10}>
          <div className="center">
            <h2 className="header-title">
              ESTE ENLACE VENCE EL <strong>{text[0]}</strong>
            </h2>
          </div>
        </Grid>
      }
      {
        model === 2 &&
        <Grid item xs={10} sm={10}>
          <div className="center">
            <h2 className="header-title">
              ESTE ENLACE VENCE EL <strong>{text[0]}</strong> A LAS <strong>{text[1]}</strong>
            </h2>
          </div>
        </Grid>
      }
    </Grid>
  </header>
);

export default HeaderComponent;
