import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import {connect} from 'react-redux';
import { uploadPhotoFile, updateTracking, updateStep, getTracking, getInspectionInfo, detectorD3API } from '../../services';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ButtonBorder from '../Button/ButtonBorder';
import ImagePlaceholder from '../../assets/images/image_placeholder.png';
import PhotoCardComp from '../photoCardComp';
import CameraComponent from '../cameraComp';
import { setPhoto } from '../../actions';
import { composeFormDataGuideImg, bytesArrayOpt } from '../../utils';

const SECTION_ID = 'Interna';

const StepComponent = ({
  photos, 
  open, 
  close, 
  step,
  fnNextStep, 
  url_id, 
  guide, 
  savingPhotoAtStore, 
  photosByGuide
}) => {
  const [openCamera, setOpenCamera] = useState(false);
  const [DataToPhoto, setDataToPhoto] = useState(null);
  const [d3Summary, setD3Summary] = useState(null);

  useEffect(()=>{
    if (step < 5) {
      updateStep(url_id, 5);
      updateTracking('Web App: Alta de fotos interior', url_id, 5);
    }
    getPhotosUrl();
  },[step, url_id]);
  

  const getPhotosUrl = async () => {
    const { photos, _id } = await getInspectionInfo(url_id);
    const photoInspection = photos[0];
    urlToArray(photoInspection, _id)
  };

  const urlToArray = (urlsImage, id) => {
    const photoToInspectionD3 = dataToEvaluation(urlsImage);
    d3Analysis(photoToInspectionD3, id)

  }

  const dataToEvaluation = (data) => {
    const dataInspection = data[1].map((item) => ({
      url: item.photo,
      tag: item.name
    }));

    return dataInspection
  }

  const d3Analysis = async (images, id) => {
    const d3Services = await detectorD3API(images, id, 5, 'Web App: Alta de fotos interior');
    await updateTracking('Web App: Alta de fotos interior', url_id, 5, d3Summary);
    setD3Summary(d3Services);
  };

  const handlerNext = () => {
    updateTracking('Web App: Alta de fotos interior', url_id, 5, d3Summary);
    fnNextStep(4);
  };

 

  const handleStoragePhoto = async (photo) => {
    savingPhotoAtStore(DataToPhoto.name, DataToPhoto.position, photo)
    const byteArrays = bytesArrayOpt(photo);
    const formDataObj = composeFormDataGuideImg('image/jpeg', byteArrays, url_id, DataToPhoto.name, SECTION_ID, DataToPhoto.position);
    const res = await uploadPhotoFile(formDataObj);
  }

  const getPhotoDataFromPhotosGuide = ( photoName ) => {
    const info = photosByGuide.get(SECTION_ID) ? photosByGuide.get(SECTION_ID).filter( ({ name }) => name === photoName || name === ` ${photoName}` ) : null;
    if ( info && info.length > 0) return info[0].photo;
    return null;
  }
  
  const closeCamera = () => {
    console.log('runnig close');
    setOpenCamera(false);
  }
  
  const GuideInternalInfo = guide ? guide.photos.filter( ({ name }) => name === SECTION_ID || name === ` ${SECTION_ID}`)[0] : null

  return (
    <>      
      <div 
        style={{
          display:'flex', 
          alignItems:'center', 
        }}
      >
        <h3>Fotos Interior</h3>
      </div> 
      <div style={{height:'30vh', display:'flex', justifyContent:'space-evenly', flexWrap:'wrap'}}>
          {
            false && GuideInternalInfo && GuideInternalInfo.related.map(
              data => 
              <Grid item xs={6}>
                <div 
                  onClick={() => fnNextStep(4)}
                  style={{
                    display:'flex',
                    height: 'auto',
                    flexDirection: 'column',
                    alignItems:'center',
                    justifyContent: 'center',
                    border: '1px dotted #333',
                    padding: '20px'
                  }}
                >
                  <img 
                    src={ImagePlaceholder} 
                    width="20%"
                  />
                  <p>{data.name}</p>
                </div>
              </Grid>
            )
          }
          {
            GuideInternalInfo && GuideInternalInfo.related.map(
              data => 
                <PhotoCardComp 
                  name={data.name} 
                  id={data.name.split(' ').join('')} 
                  clickAction={
                    () => { 
                      setDataToPhoto({name: data.name, position: data.position, id:data.name.split(' ').join('')}); 
                      setOpenCamera(true) 
                    }
                  }
                  image={
                    getPhotoDataFromPhotosGuide(data.name)
                  }
                />
            )
          }
        
      </div>
      <div style={{height:'30vh', position:'relative'}}>
        <div style={{position:'absolute', bottom:0, width:'100%'}}>
          <div style={{width:'100%', display:'flex', justifyContent: 'center'}}>
            <ButtonBorder 
              text={'SIGUIENTE'}
              clickAction={handlerNext}
            />
          </div>
        </div>
      </div>
      <CameraComponent 
        storagePhoto={handleStoragePhoto}
        openExter={openCamera}
        afterPhotoFn={closeCamera}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return { 
    photos: state,
    url_id: state.url_id,
    guide: state.guide,
    photosByGuide: state.photosByGuide,
    step: state.step
  }
}
const mapDispatchToProps = dispatch => {
  return {
    savingPhotoAtStore: (name, position, photo) => dispatch(setPhoto(SECTION_ID, name, position, photo))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepComponent);