import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { v1 as uuidv1 } from 'uuid';
import piexif from 'piexifjs';
import { connect } from 'react-redux';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { savePhoto } from '../../actions';
import { config } from '../../config';
import LandscapeIcon from '../../assets/images/landscape_vector.png';
import GridIcon from '../../assets/images/grid_vector.png';
import LightIcon from '../../assets/images/light_vector.png';
import FlashIcon from '../../assets/images/flash_vector.png';
import { bytesArrayOpt, composeFormDataImgObj, composeExifData } from '../../utils';
import '../../styles/camera.css';

const CameraComponent = ({ headerOptions, footerOptions, storagePhoto, openExter, afterPhotoFn }) => {

    const [open, setOpen] = useState(openExter || false);
    
    useEffect(()=>{
        setOpen(openExter)
    },[openExter])

    const handleTakePhoto = async (dataUri) => {
        try {
            storagePhoto(dataUri)
            if (afterPhotoFn !== null) { afterPhotoFn(); }
        } catch (e) {

        }
    }

    return (
        <Modal
            open={open}
            onClose={()=>setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={{  
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                width: '100vw'
            }}>

                {   
                    headerOptions &&
                    <div
                        style={{
                        display:'flex', 
                        alignItems:'center',
                        justifyContent: 'space-start',
                        backgroundColor: headerOptions.headerBackground ?? '#fff',
                        width:'100vw',
                        height:'10vh'
                    }}
                    > 
                        <span
                            onClick={()=>setOpen(false)}
                            style={{marginLeft:'10px'}}
                        >
                            <HighlightOffIcon style={{color: '#6716F5'}}/>
                        </span>
                        <span style={{marginLeft:'10px'}}>{ headerOptions.headerTitle }</span>
                    </div>
                }
                {
                    !headerOptions &&
                    <span
                        onClick={()=>setOpen(false)}
                        style={{
                            margin: '30px',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 99
                        }}
                    >
                        <HighlightOffIcon style={{color: '#fff', fontSize:40}}/>
                    </span>
                }
                <div>
                    <Camera
                        onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        imageType = {IMAGE_TYPES.JPG}
                        isImageMirror={false}
                        idealResolution={{
                            width : Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0), 
                            height : Math.max(document.documentElement.clientHeight * 0.8 || 0, window.innerHeight* 0.8 || 0)
                        }}
                    />
                </div>
                {
                    footerOptions &&
                        <div
                        style={{
                            display:'flex', 
                            justifyContent: 'space-evenly',
                            alignItems:'center',
                            backgroundColor: '#fff',
                            width:'100vw',
                            height:'10vh'
                        }}
                        >
                            <img src={LightIcon} height="50%" width="auto" />
                            <img src={FlashIcon} height="50%" width="auto" />
                            <img 
                                onClick={()=>window.screen.orientation.lock('landscape')}
                                src={LandscapeIcon} height="50%" width="auto" 
                            />
                            <img src={GridIcon} height="50%" width="auto" />
                        </div>
                }
            </div>
                
        </Modal>
  );
}
export default CameraComponent;
