import React, { useState } from 'react'
import { connect } from 'react-redux';
import VideoRoomComponent from '../../components/videocall/VideoRoomComponent';

const VideoCall = ({ _room }) => {
  const [room, setRoom] = useState(_room ? _room : sessionStorage.getItem('current__room'));
  return (
    <VideoRoomComponent sessionName={room} />
  );
};

const mapStateToProps = (state) => {
  return {
    url_id: state.url_id,
    _room: state.room,
  }
}

export default connect(
  mapStateToProps,
  null
)(VideoCall);
