import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },

  header: {
    marginLeft: 0,
    padding: 25,
    overflowX: "hidden",
    background: theme.palette.primary.main,
    color: "#fff",
  },

  alignOnline: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  title: {
    fontSize: 16,
    color: "#fff",
    ["@media (max-width:601px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: 16,
    },
  },

  buttonContainer: {
    textAlign: "right",
    padding: "10px",
  },

  button: {
    width: "150px",
    height: "48px",
    fontSize: "14px",
    backgroundColor: theme.palette.primary.main || "#4C1DB7" + " !important",
    color: "white !important",
    fontWeight: "bold !important",
    marginTop: "35px !important",
    position: "relative",
    right: "0px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main || "#4C1DB7" + " !important",
    },
  },

  success: {
    fontSize: "14px",
    paddingTop: "15px",
  },

  formElStyles: {
    padding: 16,
  },

  blockInfo: {
    padding: "10px 0px",
    background: "#FFFFFF",
    borderRadius: "10px",
  },

  titleInfoMinor: {
    color: "#474747",
    fontSize: 14,
    fontWeight: 400,
  },

  blockInfoUnderlined: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgb(196, 196, 196)",
    margin: "0px 16px",
    padding: "0.5rem",
    color: "#777778",
    fontSize: 14,
    fontWeight: 400,
  },

  blockInfoWithoutUnderlined: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // borderBottom: "1px solid rgb(196, 196, 196)",
    margin: "0px 16px",
    padding: "0.5rem",
    color: "#777778",
    fontSize: 14,
    fontWeight: 400,
  },

  loading: {
    position: "relative",
    marginTop: "50px",
  },

  mainCost: {
    backgroundColor: "#141B6A",
    borderRadius: "8px",
    padding: "4px",
    fontSize: "14px",
    width: "75px",
    textAlign: "center",
    color: "white",
  },

  percentageDamage: {
    backgroundColor: "#6C63FF",
    borderRadius: "8px",
    padding: "4px",
    fontSize: "14px",
    width: "75px",
    textAlign: "center",
    color: "white",
  },

  titleSection : {
    width: '100%',
    backgroundColor: '#6716F5',
    height: '55px',
    padding: '8px',
    color: 'white',
  }
}));
