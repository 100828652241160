import React, { useEffect, useState } from 'react';
import CameraView from './camera_view';
import IntroCarView from './intro_car_view';
import PhotoSlide from './photo_slide';
import StatusPhotos from './status_photos';

const NavModal = ({ close, fnNextStep }) => {

  const [step, setStep] = useState(0);

  const renderStep = () => {
    if (step === 0){
      return (
        <IntroCarView
          open={setStep}
          close={setStep}
          fnNextStep={fnNextStep}
        />
      )
    } 
    if (step === 1){
      return (
        <CameraView
          next={setStep}
          close={setStep}
        />
      )
    } 
    if (step === 2){
      return (
        <PhotoSlide
          next={setStep}
          close={setStep}
        />
      )
    } 
    if (step === 3){
      return (
        <StatusPhotos
          next={setStep}
          close={setStep}
        />
      )
    }
  }

  return (
    <>
      
      {/* <IntroCarView
        open={setStep}
        close={setStep}
        fnNextStep={fnNextStep}
      /> */}
      
      {
        renderStep()
      }
    </>
  );
}

export default NavModal;