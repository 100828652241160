import React, { useEffect } from 'react';
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  useParams,
  Switch,
  Route
} from "react-router-dom";
import {
  connect
} from 'react-redux';
import { saveUrlId, saveUrlRoom } from './actions';

import Loading from './pages/loading';
import Introduction from './pages/introduction';
import IntroductionStepTwo from './pages/introduction_step_2';
import IntroductionStepThree from './pages/introduction_step_3';
import Inspection from './pages/inspection';
import Success from './pages/success';
import Videochat from './pages/videoConference/routes';

var getParams = function (url) {
	var params = {};
	var parser = document.createElement('a');
	parser.href = url;
	var query = parser.search.substring(1);
	var vars = query.split('&');
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=');
		params[pair[0]] = decodeURIComponent(pair[1]);
  }
  
	return params;
};

function App({ savingUrlId, savingUrlRoom }) {
  
  const params = getParams(window.location.href);
  if (params.id){
    savingUrlId(params.id);
  }
  if (params.room){
    savingUrlRoom(params.room);
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Loading id={params.id} />
        </Route>
        <Route exact path="/inspection">
          <Loading id={params.id} />
        </Route>
        <Route exact path="/1">
          <Introduction id={params.id} />
        </Route>
        <Route exact path="/2">
          <IntroductionStepTwo />
        </Route>
        <Route exact path="/3">
          <IntroductionStepThree />
        </Route>
        <Route exact path="/autoinspection">
          <Inspection />
        </Route>
        <Route exact path="/success">
          <Success />
        </Route>
        <Route path="/call">
          <Videochat />
        </Route>
      </Switch>
    </Router>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    savingUrlId: id => {
      dispatch(saveUrlId(id))
    },
    savingUrlRoom: room => {
      dispatch(saveUrlRoom(room))
    }
  }
}

export default connect(
  null,
  mapDispatchToProps
)(App);