import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {
  BrowserRouter as Router,
  useHistory
} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ButtonBorder from '../Button/ButtonBorder';
import ImagePlaceholder from '../../assets/images/image_placeholder.png';
import CameraComponent from '../cameraComp';
import PhotoCardComponent from '../photoCardComp';
import { setPhoto, saveId } from '../../actions';
import { config } from '../../config';
import { uploadPhotoFile, updateTracking, updateStep } from '../../services';
import { composeFormDataGuideImg, bytesArrayOpt } from '../../utils';

const SECTION_ID = 'Documentacion';

const StepComponent = ({
  photos, 
  open, 
  savingId, 
  close, 
  url_id,
  guide, 
  savingPhotoAtStore, 
  guidesavingPhotoAtStore, 
  photosByGuide,
  fnNextStep
}) => {
  const [openCamera, setOpenCamera] = useState(false);
  const [comments, setComments] = useState('');
  const [DataToPhoto, setDataToPhoto] = useState(null);

  let history = useHistory();
  
  useEffect(() => {
    updateTracking('Web App: Carnet de conducir', url_id, 6);
    updateStep(url_id, 6);
  }, []);

  const handleStoragePhoto = async (photo) => {
    savingPhotoAtStore(DataToPhoto.name, DataToPhoto.position, photo)
    const byteArrays = bytesArrayOpt(photo);
    const formDataObj = composeFormDataGuideImg('image/jpeg', byteArrays, url_id, DataToPhoto.name, SECTION_ID, DataToPhoto.position);
    const res = await uploadPhotoFile(formDataObj);
  }

  const getPhotoDataFromPhotosGuide = ( photoName ) => {
    const info = photosByGuide.get(SECTION_ID) ? photosByGuide.get(SECTION_ID).filter( ({ name }) => name === photoName || name === ` ${photoName}` ) : null;
    if ( info && info.length > 0) return info[0].photo;
    return null;
  }

  const closeCamera = () => setOpenCamera(false)
  
  const handleOnClick = () => {

    if (comments !== '') {

      const commentsJson = JSON.stringify({ comments });
      axios.put(`${config.SERVER_URL}/inspections/temporary/${url_id}`, commentsJson,{
      headers: {
        'Content-Type': 'application/json'
      }});
    }

    // history.push("/success");
    fnNextStep(7);
  }

  const handleOnClickCamera = (id) => {
    savingId(id);
    open();

  }

  const documentationInfo = guide ? guide.photos.filter( ({ name }) => name === SECTION_ID || name === ` ${SECTION_ID}` )[0] : null


  return (
    <>
      <div style={{
        height: '80vh'
      }}>
        
        <h2>Documentacion</h2>
        {
          false && documentationInfo && documentationInfo.related.map( 
            ({ name }) => 
              <Grid container>
                <Grid item xs={12}>
                  <p>{name}</p>
                  <div 
                    style={{
                      display:'flex',
                      flexDirection: 'column',
                      alignItems:'center',
                      justifyContent: 'center',
                      border: photos[11].images ? 'unset' : '1px dotted #333',
                      padding: '30px 20px',
                      width: '320px',
                      height: '120px',
                      boxSizing: 'border-box',
                      backgroundImage: photos[11].images ? `url(${photos[11].images[photos[11].images.length-1].content})`: 'unset',
                      backgroundRepeat: photos[11].images ? 'no-repeat' : 'unset',
                      backgroundSize: photos[11].images ? 'contain' : 'unset',
                      backgroundPosition: photos[11].images ? 'center' : 'unset',

                    }}
                    onClick={() => handleOnClickCamera(11)}
                  >
                    {
                      photos[11].images ? <></>
                      : 
                      <img 
                        src={ImagePlaceholder} 
                        style={{
                          width: '20%',
                          height: 'auto'
                        }}
                      />
                    }
                  </div>
                </Grid>
              </Grid>
          )
        }
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}>
          {
            documentationInfo && documentationInfo.related.map( 
              ({ name, position }) => 
                <PhotoCardComponent 
                  id={name.split(' ').join('')}
                  name={name} 
                  clickAction={()=>{ setDataToPhoto({name, position, id:name.split(' ').join('')}); setOpenCamera(true) }}
                  image={
                    getPhotoDataFromPhotosGuide(name)
                  }
                />
            )
          }
        </div>
        <Grid container>
          <Grid item xs={12}>
            <div style={{marginBottom:10}}>
              <label>Comentarios</label>
            </div>
            <div>
              
              <TextField
                fullWidth={true}
                name="comment"
                variant="outlined"
                placeholder={'Escribe un comentario'}
                onChange={ev => setComments(ev.target.value)}
              />
              
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div 
              style={{
                width:'100%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <ButtonBorder 
                text={'SIGUIENTE'}
                clickAction={handleOnClick}
              />
            </div>
          </Grid>
        </Grid>
        <CameraComponent 
          storagePhoto={handleStoragePhoto}
          openExter={openCamera}
          afterPhotoFn={closeCamera}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    photos: state.photos,
    url_id: state.url_id,
    guide: state.guide,
    photosByGuide: state.photosByGuide
  }
}

const mapDispatchToProps = dispatch => {
  return {
    savingId: (id, data) => dispatch(saveId(id, data)),
    savingPhotoAtStore: (name, position, photo) => dispatch(setPhoto(SECTION_ID, name, position, photo))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepComponent);